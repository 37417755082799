<template>
  <div class="wrapper contact-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <h1>{{ $t("CONTACT") }}</h1>
          <p class="notice">
            {{ $t("To contact customer support, please") }}<br />
            {{ $t("send your inquiry using the form below.") }}
          </p>
          <div class="form">
            <form>
              <div class="input-area">
                <input type="text" name="userid" :value="userid" readonly />
              </div>
              <div class="input-area select">
                <select name="type" v-model="type">
                  <option value="" selected>
                    {{ $t("Select your inquiry") }}
                  </option>
                  <option :value="$t('Login / Password')">
                    {{ $t("Login / Password") }}
                  </option>
                  <option :value="$t('Deposit / Withdrawal')">
                    {{ $t("Deposit / Withdrawal") }}
                  </option>
                  <option
                    :value="$t('Two-factor authentication / Email address')"
                  >
                    {{ $t("Two-factor authentication / Email address") }}
                  </option>
                  <option :value="$t('Plan Purchase')">
                    {{ $t("Plan Purchase") }}
                  </option>
                  <option :value="$t('Bonus / AP Rewards')">
                    {{ $t("Bonus / AP Rewards") }}
                  </option>
                  <option :value="$t('Cancellation')">
                    {{ $t("Cancellation") }}
                  </option>
                  <option :value="$t('Others')">
                    {{ $t("Others") }}
                  </option>
                </select>
              </div>
              <div class="input-area">
                <input
                  type="email"
                  name="email"
                  v-model="mail"
                  :placeholder="$t('Email address')"
                />
              </div>
              <div class="input-area">
                <textarea
                  name="question"
                  :placeholder="$t('Please enter your question in detail.')"
                  v-model="content"
                ></textarea>
              </div>
              <div class="form-btn" @click="submit">
                <button type="button" class="link-over">
                  {{ $t("CONTACT") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <Footer title="Contact" />
    <van-overlay :show="loading">
      <div class="loading-position" @click.stop>
        <van-loading type="spinner" color="#1989fa" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
import LoginUser from "@/js/loginUser.js";
import { Notify } from "vant";
import Notice from "@/js/api/notice.js";

export default {
  name: "Contact",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      title: "Contact",
      mail: "",
      type: "",
      userid: LoginUser.getUserName(),
      content: "",
      loading: false,
    };
  },
  methods: {
    submit() {
      //验证输入项目
      if (!this.type) {
        Notify({
          type: "danger",
          message: this.$t("Select your inquiry"),
        });
        return;
      }
      if (!this.mail) {
        Notify({
          type: "danger",
          message: this.$t("{item} is required.", {
            item: this.$t("Email address"),
          }),
        });
        return;
      }
      if (!this.content) {
        Notify({
          type: "danger",
          message: this.$t("Please enter your question in detail."),
        });
        return;
      }

      //提交数据
      let postData = {
        title: `${this.type}(${this.mail})`,
        content: this.content,
      };
      this.loading = true;
      Notice.postInquiryAsync(postData)
        .then(() => {
          this.content = "";
          this.type = "";
          this.mail = "";
          Notify({ type: "success", message: "success" });
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {},
  mounted: function () {},
};
</script>
