<template>
  <div class="wrapper signup-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left"></div>
        <div class="right">
          <h1>
            {{ $t("E-MAIL ADDRESS CHANGE COMPLETED") }}
          </h1>
          <div class="change-email-box">
            <p>{{ $t("Email address change has been completed.") }}</p>
            <p>
              {{
                $t(
                  "You can now use your account with your newly registered e-mail address."
                )
              }}
            </p>
            <div class="button">
              <button
                type="button"
                class="link-over"
                v-on:click="toReturnBtn()"
              >
                {{ $t("BACK TO TOP") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <Footer title="ChangeEmail3" />
  </div>
</template>

<style lang="less" scoped>
.header {
  /deep/ .grade {
    display: none !important;
  }
  /deep/ .detail {
    display: none !important;
  }
}
.signup-page {
  /deep/ .fix-bar {
    padding: 60px 0 80px;
  }
  /deep/ .footer {
    padding-bottom: 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 9;
    .footer-fixed-menu {
      display: none;
    }
    .page-nav {
      display: none;
    }
    .language {
      .logo {
        img {
          display: none;
        }
      }
      .select-box {
        @media screen and (min-width: 767px) {
          margin-top: 0;
        }
      }
    }
  }
}
.change-email-box {
  width: 100%;
  padding: 0 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.change-email-box p {
  font-size: 14px;
  text-align: center;
  letter-spacing: 1px;
  line-height: 1.75;
  margin-bottom: 10px;
  color: #fff;
}
.change-email-box .button {
  width: 70%;
  max-width: 400px;
  margin: 20px auto 10px;
  background-color: #00ebc6;
  padding: 16px 10px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  margin-top: 60px;
  text-align: center;
}
</style>

<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "ChangeEmail3",
  components: {
    Header,
    HeaderMenu,
    Footer,
  },
  data: () => {
    return {
      userCheck: "",
      userCheck2: "",
    };
  },
  methods: {
    toReturnBtn() {
      this.$router.push("/account");
    },
  },
  computed: {},
  mounted: function () {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", vh + "px");

    var ua = navigator.userAgent.toLowerCase();
    var isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
    if (isiOS) {
      var viewport = document.querySelector('meta[name="viewport"]');
      if (viewport) {
        var viewportContent = viewport.getAttribute("content");
        viewport.setAttribute(
          "content",
          viewportContent + ", user-scalable=no"
        );
      }
    }
  },
};
</script>
