<template>
  <div class="wrapper news-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <h1>{{ title }}</h1>
          <div class="news-box">
            <div class="date">{{ date }}</div>
            <p v-html="description"></p>
          </div>
          <div class="btn">
            <router-link to="/newslist" class="link-over">{{
              $t("Back to list")
            }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <Footer :title="$t('NEWS')" />
  </div>
</template>
<!-- <style scoped lang="less">
.wrapper.news-page {
  background-color: #0836c0;
  background-image: url(/img/dashboard-other-back-img.png);
  background-position: 50% 180px;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  @media screen and (min-width: 767px) {
    background: url(/img/dashboard-pc-back-img.png), #0836c0;
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

/* News-page
----------------------------------------- */
.news-page {
  .right {
    padding: 80px 0 40px;
    @media screen and (min-width: 767px) {
      padding: 0 0 20px;
    }
  }
  h1 {
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 1px;
    margin-bottom: 40px;
    padding: 6px 20px 6px 28px;
    position: relative;
    margin-top: 20px;
    &::after {
      content: "";
      height: 100%;
      width: 5px;
      border-radius: 4px;
      position: absolute;
      left: 10px;
      top: 0;
      background-color: #fff;
    }
  }
  .news-list {
    margin: 40px 0;
    padding: 0 10px;
    a {
      display: block;
      border-bottom: 1px solid rgba(#e5e5e5, 0.4);
      padding: 14px 40px 14px 10px;
      background-image: url(/img/right-arrow-img.png);
      background-size: 12px;
      background-position: 98% 50%;
      letter-spacing: 1px;
    }
    .date {
      color: rgba(#fff, 0.6);
      font-size: 12px;
      span {
        display: inline-block;
        background-color: #ff7171;
        padding: 4px 6px;
        color: #fff;
        border-radius: 30px;
        line-height: 1;
        margin-left: 6px;
      }
    }
    h2 {
      margin: 10px 0;
      color: #fff;
      font-size: 16px;
    }
    .description {
      color: rgba(#fff, 0.6);
      font-size: 12px;
      line-height: 1.5;
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .news-box {
    padding: 0 20px;
    .date {
      margin-bottom: 20px;
      color: #fff;
      letter-spacing: 1px;
      font-size: 12px;
    }
    div {
      color: #fff;
      letter-spacing: 1px;
      line-height: 1.75;
      margin-bottom: 40px;
    }
    :deep(a) {
      color: rgb(173, 131, 171);
      text-decoration: underline;
    }
  }
  .btn {
    padding: 0 20px;
    text-align: center;
    margin-top: 60px;
    a {
      display: inline-block;
      color: #1006ff;
      padding: 14px 60px;
      border-radius: 30px;
      font-weight: 600;
      letter-spacing: 1px;
      background-color: #fff;
    }
  }
}
</style> -->
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
import Notice from "@/js/api/notice.js";

export default {
  name: "Authentication",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      date: "",
      title: "",
      description: "",
    };
  },
  created() {
    this.getNotice();
    Notice.insertUserReadAsync(this.$route.params.id);
  },
  methods: {
    getNotice() {
      let data = {
        id: this.$route.params.id,
      };
      Notice.getNoticesAsync(data).then((res) => {
        if (res) {
          this.title = res.data.title;
          this.date = res.data.createdDate;
          this.description = res.data.content;
          //   let news = [];
          //   res.data.detail.forEach((item) => {
          //     let newItem = {
          //       id: item.id,
          //       date: item.createdTimeYMD,
          //       title: item.title,
          //       link: "/news",
          //       description: item.content,
          //       newsFlag: item.readed,
          //     };
          //     news.push(newItem);
          //   });
          //   this.NEWS_ITEM = news;
          //   this.total = res.data.total;
        }
      });
    },
  },
  computed: {},
  mounted: function () {},
};
</script>
