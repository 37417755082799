<template>
  <div class="wrapper signup-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />
    <div class="div-p-top-roll">
      <p class="p-top-roll">
        ALEPHZERO DIFFUSION PROJECT ALEPHZERO DIFFUSION PROJECT ALEPHZERO
        DIFFUSION PROJECT ALEPHZERO DIFFUSION PROJECT ALEPHZERO DIFFUSION
        PROJECT ALEPHZERO DIFFUSION PROJECT ALEPHZERO DIFFUSION PROJECT
        ALEPHZERO DIFFUSION PROJECT ALEPHZERO DIFFUSION PROJECT ALEPHZERO
        DIFFUSION PROJECT ALEPHZERO DIFFUSION PROJECT ALEPHZERO DIFFUSION
        PROJECT ALEPHZERO DIFFUSION PROJECT ALEPHZERO DIFFUSION PROJECT
        ALEPHZERO DIFFUSION PROJECT ALEPHZERO DIFFUSION PROJECT ALEPHZERO
        DIFFUSION PROJECT ALEPHZERO DIFFUSION PROJECT ALEPHZERO DIFFUSION
        PROJECT ALEPHZERO DIFFUSION PROJECT ALEPHZERO DIFFUSION PROJECT
        ALEPHZERO DIFFUSION PROJECT ALEPHZERO DIFFUSION PROJECT ALEPHZERO
        DIFFUSION PROJECT
      </p>
    </div>
    <div class="contents">
      <div class="layout">
        <div class="left"></div>
        <div class="right">
          <input class="c-checkbox" type="checkbox" id="start" />
          <input class="c-checkbox" type="checkbox" id="progress1" />
          <input class="c-checkbox" type="checkbox" id="progress2" />
          <input class="c-checkbox" type="checkbox" id="progress3" />
          <input class="c-checkbox" type="checkbox" id="progress4" />
          <input class="c-checkbox" type="checkbox" id="progress5" />
          <input class="c-checkbox" type="checkbox" id="finish" />

          <input class="c-checkbox return-check" type="checkbox" id="return" />
          <input class="c-checkbox return-check" type="checkbox" id="return1" />
          <input class="c-checkbox return-check" type="checkbox" id="return2" />
          <input class="c-checkbox return-check" type="checkbox" id="return3" />

          <div class="shutter" style="display: none"></div>
          <div class="c-form__progress"></div>
          <h1>
            {{ $t("SIGN UP") }}
          </h1>
          <div class="c-formContainer">
            <!-- last -->
            <div class="c-welcome">
              {{ $t("WELCOME TO") }}<br />
              {{ $t("CDP SYSTEM") }}
              <div class="img">
                <div class="link-btn link-over">
                  <img
                    src="../assets/img/signup-btn-img.svg"
                    alt=""
                    class="blink-animation"
                  />
                </div>
              </div>
            </div>
            <!-- last -->
            <div class="btn">
              <form class="c-form" action="">
                <!-- 1 -->
                <div class="c-form__group active div-tr-y-40">
                  <label class="c-form__label" for="introducer">
                    <input
                      type="text"
                      id="introducer"
                      class="c-form__input"
                      placeholder=" "
                      v-model="introducerPeople"
                      :disabled="showIntroducer"
                      required
                    />
                    <label class="c-form__next" for="start" role="button">
                      <span class="c-form__nextIcon">
                        <img src="../assets/img/signin-arrow-img.svg" alt="" />
                      </span>
                    </label>

                    <span class="c-form__groupLabel">{{
                      $t("INTRODUCER")
                    }}</span>
                    <b class="c-form__border"></b>
                  </label>
                  <div class="c-form__group-two active div-tr-y40-x15">
                    <label class="c-form__label" for="SeatUser">
                      <input
                        type="text"
                        id="SeatUser"
                        class="c-form__input"
                        placeholder=" "
                        v-model="SeatUser"
                        :disabled="showSeatUser"
                        required
                      />
                      <label class="c-form__next" for="start" role="button">
                        <span class="c-form__nextIcon">
                          <img
                            src="../assets/img/signin-arrow-img.svg"
                            alt=""
                          />
                        </span>
                      </label>

                      <span class="c-form__groupLabel">{{
                        $t("POSITION ID（any）")
                      }}</span>
                      <b class="c-form__border"></b>
                    </label>
                  </div>
                  <div class="active div-tr-y140">
                    <span
                      class="span-button span-left"
                      :class="radio == 1 ? 'span-button-active' : ''"
                      for="left"
                      @click="
                        if (!showSeatNo) {
                          radio = '1';
                        }
                      "
                      :disabled="showSeatNo"
                      >{{ $t("LEFT") }}</span
                    >
                    <span
                      class="span-button span-right"
                      :class="radio == 2 ? 'span-button-active' : ''"
                      for="right"
                      @click="
                        if (!showSeatNo) {
                          radio = '2';
                        }
                      "
                      :disabled="showSeatNo"
                      >{{ $t("RIGHT") }}</span
                    >
                  </div>
                </div>

                <!-- ./1 -->

                <!-- 2 -->
                <div class="c-form__group">
                  <label class="c-form__label" for="username">
                    <input
                      type="text"
                      id="username"
                      class="c-form__input"
                      v-model="username"
                      placeholder=" "
                      required
                    />
                    <label class="c-form__next" for="progress1" role="button">
                      <span class="c-form__nextIcon">
                        <img src="../assets/img/signin-arrow-img.svg" alt="" />
                      </span>
                    </label>

                    <span class="c-form__groupLabel">{{
                      $t("USER NAME")
                    }}</span>
                    <b class="c-form__border"></b>
                  </label>
                  <button class="return-txt" type="button">◀︎ Back</button>
                </div>
                <!-- ./2 -->

                <!-- 3 -->
                <div class="c-form__group">
                  <label class="c-form__label" for="email">
                    <input
                      type="email"
                      id="email"
                      class="c-form__input"
                      placeholder=" "
                      v-model="email"
                      required
                    />
                    <label class="c-form__next" for="progress2" role="button">
                      <span class="c-form__nextIcon">
                        <img src="../assets/img/signin-arrow-img.svg" alt="" />
                      </span>
                    </label>

                    <span class="c-form__groupLabel">{{
                      $t("Email address")
                    }}</span>
                    <b class="c-form__border"></b>
                  </label>
                  <button class="return-txt" type="button">◀︎ Back</button>
                </div>
                <!-- ./3 -->

                <!-- 4 -->
                <div class="c-form__group">
                  <p class="code-msg">
                    {{
                      $t(
                        "Please enter the verification code sent to your email address below."
                      )
                    }}
                  </p>
                  <label class="c-form__label" for="code">
                    <input
                      type="text"
                      id="code"
                      class="c-form__input"
                      v-model="verificationCode"
                      placeholder=" "
                      required
                    />
                    <label class="c-form__next" for="progress3" role="button">
                      <span class="c-form__nextIcon">
                        <img src="../assets/img/signin-arrow-img.svg" alt="" />
                      </span>
                    </label>

                    <span class="c-form__groupLabel">{{
                      $t("Enter confirmation code")
                    }}</span>
                    <b class="c-form__border"></b>
                  </label>
                  <button class="return-txt" type="button">◀︎ Back</button>
                </div>
                <!-- ./4 -->

                <!-- 5 -->
                <div class="c-form__group">
                  <label class="c-form__label" for="fpass">
                    <!-- 解决浏览器记住密码自动填写的问题 -->
                    <input type="password" name="pwd" style="display: none" />
                    <input
                      type="password"
                      name="pwd"
                      id="fpass"
                      class="c-form__input"
                      v-model="password"
                      placeholder=" "
                    />

                    <label class="c-form__next" for="progress4" role="button">
                      <span class="c-form__nextIcon"
                        ><img src="../assets/img/signin-arrow-img.svg" alt=""
                      /></span>
                    </label>

                    <span class="c-form__groupLabel">{{ $t("PASSWORD") }}</span>
                    <b class="c-form__border"></b>
                  </label>
                  <button class="return-txt" type="button">◀︎ Back</button>
                </div>
                <!-- ./5 -->

                <!-- 6 -->
                <div class="c-form__group">
                  <label class="c-form__label" for="fpass3">
                    <input
                      type="password"
                      id="fpass3"
                      class="c-form__input"
                      v-model="confirmPassword"
                      placeholder=" "
                    />

                    <label class="c-form__next" for="progress5" role="button">
                      <span class="c-form__nextIcon"
                        ><img src="../assets/img/signin-arrow-img.svg" alt=""
                      /></span>
                    </label>

                    <span class="c-form__groupLabel">{{
                      $t("CONFIRM PASSWORD")
                    }}</span>
                    <b class="c-form__border"></b>
                  </label>
                  <button class="return-txt" type="button">◀︎ Back</button>
                </div>
                <!-- ./6 -->

                <!-- 7 -->
                <div class="c-form__group pdf-area">
                  <label class="c-form__label" for="fpass2">
                    <div class="pdf">
                      <img src="../assets/pdf/terms-pdf-1.jpg" alt="" />
                      <img src="../assets/pdf/terms-pdf-2.jpg" alt="" />
                      <img src="../assets/pdf/terms-pdf-3.jpg" alt="" />
                      <img src="../assets/pdf/terms-pdf-4.jpg" alt="" />
                      <img src="../assets/pdf/terms-pdf-5.jpg" alt="" />
                      <img src="../assets/pdf/terms-pdf-6.jpg" alt="" />
                      <img src="../assets/pdf/terms-pdf-7.jpg" alt="" />
                      <img src="../assets/pdf/terms-pdf-8.jpg" alt="" />
                    </div>
                    <!-- 利用規約に同意する -->
                    <div class="input-area">
                      <label class="cover-label" for="check">
                        <input
                          type="checkbox"
                          name=""
                          id="check"
                          placeholder=" "
                          v-model="userCheck"
                        />
                        {{ $t("I agree with the Terms") }}
                      </label>
                      <span
                        class="circle"
                        v-bind:class="{
                          done: hasChecked,
                        }"
                      >
                        <span class="bar"></span>
                      </span>
                    </div>
                    <label
                      class="done-btn c-form__nextIcon"
                      for="finish"
                      role="button"
                      v-bind:class="{
                        done: hasChecked,
                      }"
                    >
                      {{ $t("Completed") }}
                    </label>
                  </label>
                  <button class="return-txt" type="button">◀︎ Back</button>
                </div>
                <!-- ./7 -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->
    <div class="div-p-botton-roll">
      <p class="p-botton-roll">
        ALEPHZERO DIFFUSION PROJECT ALEPHZERO DIFFUSION PROJECT ALEPHZERO
        DIFFUSION PROJECT ALEPHZERO DIFFUSION PROJECT ALEPHZERO DIFFUSION
        PROJECT ALEPHZERO DIFFUSION PROJECT ALEPHZERO DIFFUSION PROJECT
        ALEPHZERO DIFFUSION PROJECT ALEPHZERO DIFFUSION PROJECT ALEPHZERO
        DIFFUSION PROJECT ALEPHZERO DIFFUSION PROJECT ALEPHZERO DIFFUSION
        PROJECT ALEPHZERO DIFFUSION PROJECT ALEPHZERO DIFFUSION PROJECT
        ALEPHZERO DIFFUSION PROJECT ALEPHZERO DIFFUSION PROJECT ALEPHZERO
        DIFFUSION PROJECT ALEPHZERO DIFFUSION PROJECT ALEPHZERO DIFFUSION
        PROJECT ALEPHZERO DIFFUSION PROJECT ALEPHZERO DIFFUSION PROJECT
        ALEPHZERO DIFFUSION PROJECT ALEPHZERO DIFFUSION PROJECT ALEPHZERO
        DIFFUSION PROJECT
      </p>
    </div>
    <Footer title="Sign up" />
  </div>
</template>

<style lang="less" scoped>
.div-tr-y-40 {
  transform: translateY(-40px);
}
.div-tr-y40 {
  transform: translateY(40px);
}
.div-tr-y40-x15 {
  transform: translate(-15px, 40px);
}
.div-tr-y140 {
  transform: translateY(140px);
}
.div-p-top-roll {
  background-color: #2c3b4d;
  position: absolute;
  top: calc(5% + 10px);
  padding: 4px 0;
  width: 100%;
  overflow: hidden;
  .p-top-roll {
    color: rgba(255, 255, 255, 0.2);
    font-size: 12px;
    letter-spacing: 2px;
    white-space: nowrap;
    font-weight: 600;
    animation-name: marquee;
    animation-timing-function: linear;
    animation-duration: 14s;
    animation-iteration-count: infinite;
  }
}
.div-p-botton-roll {
  background-color: #2c3b4d;
  position: absolute;
  top: calc(97% + 10px);
  padding: 4px 0;
  width: 100%;
  overflow: hidden;
  .p-botton-roll {
    color: rgba(255, 255, 255, 0.2);
    font-size: 12px;
    letter-spacing: 2px;
    white-space: nowrap;
    font-weight: 600;
    animation-name: marquee;
    animation-timing-function: linear;
    animation-duration: 14s;
    animation-iteration-count: infinite;
  }
}
.span-button {
  width: 46%;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 30px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 0.6s;
  cursor: pointer;
  opacity: 0.4;
  margin: 10px;
}
.c-form__group-two {
  visibility: visible !important;
  opacity: 1 !important;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  padding: 12px 15px;
  box-sizing: border-box;
  transition: 0.2s 0.2s;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 5px 10px -2px rgb(0 0 0 / 20%), 0 2px 4px -1px rgb(0 0 0 / 30%);
}
.span-left {
  padding: 12px 15%;
}
.span-right {
  padding: 12px 15%;
}
.span-button-active {
  background-color: #00eac4;
  border: 1px solid #00eac4;
  opacity: 1;
}
.header {
  /deep/ .grade {
    display: none !important;
  }
  /deep/ .detail {
    display: none !important;
  }
}
.signup-page {
  /deep/ .fix-bar {
    padding: 60px 0 80px;
  }
  /deep/ .footer {
    padding-bottom: 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 9;
    .footer-fixed-menu {
      display: none;
    }
    .page-nav {
      display: none;
    }
    .language {
      .logo {
        img {
          display: none;
        }
      }
      .select-box {
        @media screen and (min-width: 767px) {
          margin-top: 0;
        }
      }
    }
  }
}
</style>

<script>
import $ from "jquery";
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Footer from "@/components/Footer.vue";
import Account from "@/js/api/account";
import { Notify } from "vant";

export default {
  name: "Signup",
  components: {
    Header,
    HeaderMenu,
    Footer,
  },
  data: () => {
    return {
      userCheck: "",
      userCheck2: "",
      verificationCode: "",
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      userTel: "",
      realname: "",
      introducerPeople: "",
      SeatUser: "",
      radio: "1",
      showIntroducer: false,
      showSeatUser: false,
      showSeatNo: false,
      loading: false,
      isOut: true,
    };
  },
  methods: {
    onIOS() {
      let ua = navigator.userAgent.toLowerCase();
      let isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
      if (isiOS) {
        let viewport = document.querySelector('meta[name="viewport"]');
        if (viewport) {
          let viewportContent = viewport.getAttribute("content");
          viewport.setAttribute(
            "content",
            viewportContent + ", user-scalable=no"
          );
        }
      }
    },
    onFadeOut() {
      $(".fix-bar").fadeOut("slow");
      $(".c-form__toggle").addClass("remove");
      $(".c-form__group:nth-child(1)").addClass("active");
    },
    toReturnBtn() {
      this.$router.push("/top");
    },
    transcoding(data) {
      if (data) {
        if (data.indexOf(" ") > -1) {
          return data.replace(" ", "+");
        } else if (data.indexOf("%2B") > -1) {
          return data.replace("%2B", "+");
        } else if (data.indexOf("%20") > -1) {
          return data.SeatUser.replace("%20", " ");
        } else if (data.indexOf("+") > -1) {
          return data.SeatUser.replace("+", " ");
        } else if (data.indexOf("%3F") > -1) {
          return data.SeatUser.replace("%3F", "?");
        } else if (data.indexOf("%25") > -1) {
          return data.SeatUser.replace("%25", "%");
        } else if (data.indexOf("%23") > -1) {
          return data.SeatUser.replace("%23", "#");
        } else if (data.indexOf("%26") > -1) {
          return data.SeatUser.replace("%26", "&");
        } else if (data.indexOf("%3D") > -1) {
          return data.SeatUser.replace("%3D", "=");
        } else {
          return data;
        }
      } else {
        return "";
      }
    },
    init() {
      if (this.$route.query.Introducer) {
        this.SeatUser = this.transcoding(this.$route.query.SeatUser);
        this.radio = this.$route.query.SeatNo;
        this.introducerPeople = this.transcoding(this.$route.query.Introducer);
        if (this.$route.query.SeatUser) {
          this.showSeatUser = true;
        }
        if (this.SeatUser == undefined) {
          this.SeatUser = "";
        }
        if (this.$route.query.SeatNo) {
          this.showSeatNo = true;
          if (this.$route.query.SeatUser) {
            this.isOut = false;
          }
        }
        if (this.$route.query.Introducer) {
          this.showIntroducer = true;
        }
      }
    },
    register() {
      let params = {
        Email: this.email,
        verificationCode: this.verificationCode,
        Username: this.username,
        Password: this.password,
        ConfirmPassword: this.confirmPassword,
        Introducer: this.introducerPeople,
        SeatUser: this.SeatUser ? this.SeatUser : this.introducerPeople,
        SeatNo: this.radio,
        RealName: this.realname,
        PhoneNumber: this.userTel,
        isOut: this.isOut,
      };
      this.loading = true;
      Account.register(params)
        .then((response) => {
          if (response) {
            $(".c-form__group:nth-child(5)").removeClass("active");
            $(".c-formContainer .c-form").addClass("active");
            $(".c-formContainer .c-welcome").addClass("active");
            $(".c-form__progress").addClass("progress5");
          }
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    hasChecked() {
      return this.userCheck;
    },
    hasChecked2() {
      return this.userCheck2;
    },
  },
  mounted: function () {
    this.init();
    this.onFadeOut();

    let that = this;
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", vh + "px");

    var ua = navigator.userAgent.toLowerCase();
    var isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
    if (isiOS) {
      var viewport = document.querySelector('meta[name="viewport"]');
      if (viewport) {
        var viewportContent = viewport.getAttribute("content");
        viewport.setAttribute(
          "content",
          viewportContent + ", user-scalable=no"
        );
      }
    }

    $(".signup-page .c-form__toggle").on("click", function () {
      $(".fix-bar").fadeOut("slow");
    });
    $(".c-welcome .link-btn").on("click", function () {
      $(".shutter")
        .css("display", "block")
        .delay(2090)
        .queue(function () {
          location.href = "./";
          $(that).dequeue();
        });
    });

    $(".c-form__toggle").on("click", function () {
      $(".c-form__toggle").addClass("remove");
      $(".c-form__group:nth-child(1)").addClass("active");
    });
    $(".c-form__group:nth-child(1) .c-form__nextIcon").on("click", function () {
      if (that.introducerPeople != "") {
        Account.checkIntroducerSeatUserAsync(
          that.introducerPeople,
          that.SeatUser != "" ? that.SeatUser : that.introducerPeople,
          that.radio
        ).then(() => {
          $(".c-form__group:nth-child(1)").removeClass("active");
          $(".c-form__group:nth-child(2)").addClass("active");
          $(".c-form__progress").addClass("progress1");
        });
      } else {
        Notify({
          type: "danger",
          message: that.$t("{item} is required.", {
            item: that.$t("INTRODUCER"),
          }),
        });
      }
    });
    $(".c-form__group:nth-child(1) .return-txt").on("click", function () {
      $(".c-form__toggle").removeClass("remove");
      $(".c-form__group:nth-child(1)").removeClass("active");
    });

    $(".c-form__group:nth-child(2) .c-form__nextIcon").on("click", function () {
      if (that.username) {
        that.loading = true;
        Account.getExistedUser(that.username).then((res) => {
          that.loading = false;
          if (res) {
            if (res.data) {
              Notify({ type: "danger", message: that.$t("Already Exists.") });
            } else {
              $(".c-form__group:nth-child(2)").removeClass("active");
              $(".c-form__group:nth-child(3)").addClass("active");
              $(".c-form__progress").addClass("progress2");
            }
          }
        });
      } else {
        Notify({
          type: "danger",
          message: that.$t("{item} is required.", {
            item: that.$t("USER NAME"),
          }),
        });
      }
    });
    $(".c-form__group:nth-child(2) .return-txt").on("click", function () {
      $(".c-form__group:nth-child(2)").removeClass("active");
      $(".c-form__group:nth-child(1)").addClass("active");
      $(".c-form__progress").removeClass("progress1");
    });

    $(".c-form__group:nth-child(3) .c-form__nextIcon").on("click", function () {
      if (that.email) {
        that.loading = true;
        Account.EmailVerificationCodeNoLogin(that.email)
          .then((res) => {
            that.loading = false;
            if (res) {
              // if (res.data) {
              //   Notify({ type: "danger", message: this.$t("Already Exists.") });
              // } else {
              $(".c-form__group:nth-child(3)").removeClass("active");
              $(".c-form__group:nth-child(4)").addClass("active");
              $(".c-form__progress").addClass("progress3");
              // }
            }
          })
          .finally(() => {
            that.loading = false;
          });
      } else {
        Notify({
          type: "danger",
          message: that.$t("{item} is required.", {
            item: that.$t("Email address"),
          }),
        });
      }
    });
    $(".c-form__group:nth-child(3) .return-txt").on("click", function () {
      $(".c-form__group:nth-child(3)").removeClass("active");
      $(".c-form__group:nth-child(2)").addClass("active");
      $(".c-form__progress").removeClass("progress2");
    });

    $(".c-form__group:nth-child(4) .c-form__nextIcon").on("click", function () {
      if (that.verificationCode != "") {
        that.loading = true;
        Account.VerifyEmailNoLogin(that.email, that.verificationCode)
          .then((res) => {
            that.loading = false;
            if (res) {
              if (res.data) {
                Notify({ type: "danger", message: that.$t("Already Exists.") });
              } else {
                $(".c-form__group:nth-child(4)").removeClass("active");
                $(".c-form__group:nth-child(5)").addClass("active");
                $(".c-form__progress").addClass("progress4");
              }
            }
          })
          .finally(() => {
            that.loading = false;
          });
      } else {
        Notify({
          type: "danger",
          message: that.$t("{item} is required.", {
            item: that.$t("Enter confirmation code"),
          }),
        });
      }
    });
    $(".c-form__group:nth-child(4) .return-txt").on("click", function () {
      $(".c-form__group:nth-child(4)").removeClass("active");
      $(".c-form__group:nth-child(3)").addClass("active");
      $(".c-form__progress").removeClass("progress3");
    });

    $(".c-form__group:nth-child(5) .c-form__nextIcon").on("click", function () {
      if (that.password) {
        if (that.password.length > 5) {
          $(".c-form__group:nth-child(5)").removeClass("active");
          $(".c-form__group:nth-child(6)").addClass("active");
          $(".c-form__progress").addClass("progress2");
        } else {
          Notify({
            type: "danger",
            message: that.$t(
              "The Password must be at least 6 and at max 100 characters long."
            ),
          });
        }
      } else {
        Notify({
          type: "danger",
          message: that.$t("{item} is required.", {
            item: that.$t("PASSWORD"),
          }),
        });
      }
    });
    $(".c-form__group:nth-child(5) .return-txt").on("click", function () {
      $(".c-form__group:nth-child(5)").removeClass("active");
      $(".c-form__group:nth-child(4)").addClass("active");
      $(".c-form__progress").removeClass("progress4");
    });

    $(".c-form__group:nth-child(6) .c-form__nextIcon").on("click", function () {
      if (that.confirmPassword == that.password) {
        $(".c-form__group:nth-child(6)").removeClass("active");
        $(".c-form__group:nth-child(7)").addClass("active");
        $(".c-form__progress").addClass("progress6");
      } else {
        Notify({
          type: "danger",
          message: that.$t(
            "The password and confirmation password do not match."
          ),
        });
      }
    });
    $(".c-form__group:nth-child(6) .return-txt").on("click", function () {
      $(".c-form__group:nth-child(6)").removeClass("active");
      $(".c-form__group:nth-child(5)").addClass("active");
      $(".c-form__progress").removeClass("progress4");
    });
    $(".c-form__group:nth-child(7) .return-txt").on("click", function () {
      $(".c-form__group:nth-child(7)").removeClass("active");
      $(".c-form__group:nth-child(6)").addClass("active");
      $(".c-form__progress").removeClass("progress4");
    });

    // $(".c-form__group:nth-child(7) .c-form__nextIcon").on("click", function () {
    //   $(".c-form__group:nth-child(7)").removeClass("active");
    //   $(".c-form__group:nth-child(8)").addClass("active");
    //   $(".c-form__progress").addClass("progress7");
    // });
    // $(".c-form__group:nth-child(7) .return-txt").on("click", function () {
    //   $(".c-form__group:nth-child(7)").removeClass("active");
    //   $(".c-form__group:nth-child(6)").addClass("active");
    //   $(".c-form__progress").removeClass("progress6");
    // });

    $(".c-form__group:nth-child(7) .c-form__nextIcon").on("click", function () {
      that.register();
    });
  },
};
</script>
