import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "./js/i18n/i18n";
import Vant from "vant";
import "vant/lib/index.css";
import Clipboard from "v-clipboard3";

import Common from "./js/common";
import Definitions from "./js/definitions";
import Account from "./js/api/account";

router.beforeEach((to, from, next) => {
  const accessToken = localStorage.getItem(Definitions.StorageKeys.accessToken);
  let anonymousPages = [
    "Signin",
    "Signup",
    "Forgot",
    "ForgotPassword",
    "ForgotPassword2",
    "ResetPassword",
    "DonePassword",
    "ForgotAccount",
    "ForgotAccount2",
    "DoneAccount",
    "Maintenance",
  ];
  if (anonymousPages.indexOf(to.name) >= 0) {
    return next();
  }
  // if (to.name === "Signin" || to.name === "Signup") {
  //   return next();
  // }
  if (Common.isEmpty(accessToken)) {
    return next({
      path: "/signin",
    });
  } else {
    return Account.getLoginInfoAsync().then(() => next());
  }
});

window.onresize = () => {
  store.commit("setScreenWidth", document.body.clientWidth);
};

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(Vant)
  .use(Clipboard)
  .mount("#app");
