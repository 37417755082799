import Definitions from "../js/definitions";
import router from "../router";
import { i18n } from "./i18n/i18n";
// import { ElMessage, ElMessageBox } from "element-plus";
import { Notify, Toast, Dialog } from "vant";
import Store from "@/store";

const Common = {
  backendUrl: process.env.VUE_APP_BACKEND_URL.endsWith("/")
    ? process.env.VUE_APP_BACKEND_URL.slice(0, -1)
    : process.env.VUE_APP_BACKEND_URL,
  hostUrl: process.env.VUE_APP_HOST_URL.endsWith("/")
    ? process.env.VUE_APP_HOST_URL.slice(0, -1)
    : process.env.VUE_APP_HOST_URL,
  wallectConnectChainId: process.env.VUE_APP_WALLETCONNECT_CHAIN_ID,
  spender: process.env.VUE_APP_SPENDER,
  gasPrice: 50,

  //#region function
  isDevelopment: () => process.env.NODE_ENV === "development",

  generateApiUrl: (relativeUrl) =>
    `${Common.backendUrl}${Common.modifyRelativeUrl(relativeUrl)}`,

  modifyRelativeUrl: (relativeUrl) => {
    let url = relativeUrl.startsWith("/") ? relativeUrl : `/${relativeUrl}`;
    url = url.indexOf("?") !== -1 ? `${url}&` : `${url}?`;
    url = `${url}culture=${localStorage.getItem(
      Definitions.StorageKeys.language
    )}`;
    return url;
  },

  generateEthereumTransactionLink: (txId, displayCount) => {
    if (Common.isEmpty(txId) === false) {
      let txUrl = "https://etherscan.io/tx/" + txId;
      return `< a href="${txUrl}" target="_blank">${Common.getDisplayTxId(
        txId,
        displayCount
      )}</ a>`;
    } else {
      return "";
    }
  },

  getDisplayTxId: (txId, displayCount) => {
    let displayTxid = txId;
    if (displayCount) {
      displayTxid = `${txId.substring(0, displayCount)}...${txId.substring(
        txId.length - displayCount
      )}`;
    }
    return displayTxid;
  },

  formatQueryString: (params) => {
    let paramString = "";
    for (var key in params) {
      paramString += `&${key}=${encodeURIComponent(params[key])}`;
    }
    if (!paramString) {
      paramString = paramString.replace("&", "");
    }
    return paramString;
  },

  loggedIn: (accessToken, refreshToken) => {
    localStorage.setItem(Definitions.StorageKeys.accessToken, accessToken);
    localStorage.setItem(Definitions.StorageKeys.refreshToken, refreshToken);
  },

  loggedOut: () => {
    localStorage.removeItem(Definitions.StorageKeys.accessToken);
    localStorage.removeItem(Definitions.StorageKeys.refreshToken);
    sessionStorage.removeItem(Definitions.StorageKeys.userInfo);
    router.push({ name: "Signin" });
  },

  handleError: (error, message, showLoginError) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          if (message) {
            Common.error(message);
          } else {
            if (error.response.data.errors) {
              Common.error(
                Common.generateErrorMessage(
                  Common.isEmpty(error.response.data.errors) === true
                    ? error.response.data
                    : error.response.data.errors
                ),
                true
              );
            } else {
              Common.error(error.response.data);
            }
          }
          break;

        case 401:
        case 403:
          if (showLoginError) {
            Common.error(i18n.global.t("Invalid login attempt."));
          }
          break;

        default:
          Common.error(error);
      }
    } else {
      Common.error(error);
    }
  },

  success: (msg) => {
    // return ElMessage({
    //   type: "success",
    //   message: msg,
    //   showClose: true,
    // });
    return Notify({ type: "success", message: msg });
  },

  // eslint-disable-next-line no-unused-vars
  error: (error, dangerouslyUseHTMLString) => {
    const msg = error.message ?? error;
    // return ElMessage({
    //   message: msg,
    //   dangerouslyUseHTMLString: dangerouslyUseHTMLString === true,
    //   type: "error",
    //   showClose: true,
    // });
    return Notify({ type: "danger", message: msg });
  },

  warning: (msg) => {
    // return ElMessage({
    //   type: "warning",
    //   message: msg,
    //   showClose: true,
    // });
    return Notify({ type: "warning", message: msg });
  },

  toastSuccess: (msg) => {
    // return ElNotification({
    //   title: "Success",
    //   message: msg,
    //   type: "success",
    // });
    return Toast.success(msg);
  },

  // eslint-disable-next-line no-unused-vars
  toastFail: (error, dangerouslyUseHTMLString) => {
    const msg = error.message ?? error;
    // return ElNotification({
    //   title: "Error",
    //   message: msg,
    //   type: "error",
    //   dangerouslyUseHTMLString: dangerouslyUseHTMLString === true,
    // });
    return Toast.fail(msg);
  },

  loading: (message) => {
    // return ElLoading.service({
    //   lock: true,
    //   text: message,
    //   background: "rgba(0, 0, 0, 0.7)",
    // });
    return Toast.loading(message);
  },

  closeLoading: (loading) => {
    if (loading) {
      if (loading.close) loading.close();
      if (loading.clear) loading.clear();
    }
  },

  metaMask: () => {
    const msg = i18n.global.t(
      "Please enter the URL from the browser in the app version of Metamask and launch it."
    );
    // return ElMessageBox.confirm(msg, {
    //   confirmButtonText: "Ok",
    //   showCancelButton: false,
    //   type: "warning",
    // })
    //   .then(() => {
    //     // window.location.reload();
    //   })
    //   .catch(() => {
    //     // window.location.reload();
    //   });
    return Dialog.alert({
      message: msg,
      confirmButtonText: "Ok",
      confirmButtonColor: "#000000",
    }).then(() => {
      // on close
    });
  },

  generateErrorMessage: (errors) => {
    let message = "";
    if (Array.isArray(errors)) {
      errors.forEach(function (item) {
        if (message) message += "\n";
        message += item;
      });
    } else {
      let keyIndex = 0;
      for (const key in errors) {
        const value = errors[key];
        if (keyIndex > 0) message += "\n";
        // message += `·${i18n.t(key)}`;
        // message += `·${key}`;
        // message += "\n";
        if (Array.isArray(value)) {
          value.forEach(function (item, index) {
            if (index > 0) message += "\n";
            message += item;
          });
        } else {
          message += value;
        }
        keyIndex++;
      }
    }
    return message;
  },

  utcFormat: (utc) => {
    let date = new Date(utc);
    let seperator1 = ".";
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let strDate = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    if (month >= 1 && month <= 9) {
      month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
    if (hour >= 0 && hour <= 9) {
      hour = "0" + hour;
    }
    if (minute >= 0 && minute <= 9) {
      minute = "0" + minute;
    }
    let formatDate =
      year +
      seperator1 +
      month +
      seperator1 +
      strDate +
      " " +
      hour +
      ":" +
      minute;
    return formatDate;
  },

  isMobileScreenWidth: () => Store.state.screenWidth <= 768,

  validEthAddressFormat: (address) => {
    if (
      address.startsWith("0x") === false &&
      address.startsWith("0X") === false
    ) {
      return false;
    }
    if (address.length !== 42) {
      return false;
    }
    var reg = new RegExp(/^[0-9A-Za-z]+$/);
    if (reg.test(address) === false) {
      return false;
    }
    return true;
  },

  validBtcAddressFormat: (address) => {
    var reg = new RegExp(/^[0-9A-Za-z]+$/);
    if (reg.test(address) === false) {
      return false;
    }
    return true;
  },

  formatNumber: (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substring(0, j) + thousands : "") +
      i.substring(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  },

  isEmpty: (obj) => {
    if (typeof obj === "undefined" || obj === null || obj === "") {
      return true;
    } else {
      return false;
    }
  },

  validatePasswordLength: (password) =>
    Definitions.PasswordMinimumLength <= password.length &&
    password.length <= Definitions.PasswordMaximumLength,

  validateDate: (originalYear, originalMonth, originalDay) => {
    var date = new Date(originalYear, originalMonth - 1, originalDay);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    return year == originalYear && month == originalMonth && day == originalDay;
  },

  getWallectConnectRpc: () => {
    switch (Common.wallectConnectChainId) {
      case "56":
        return {
          56: "https://bsc-dataseed.binance.org",
        };
      case "97":
        return {
          97: "https://data-seed-prebsc-1-s1.binance.org:8545",
        };
      default:
        return {};
    }
  },
  //#endregion
};

export default Common;
