<template>
  <div class="pc-only">
    <div class="top-menu">
      <ul class="list">
        <li>
          <router-link to="./" class="link-over">
            <b>{{ $t("My Wallet") }}</b>
          </router-link>
        </li>
      </ul>
      <p>{{ $t("MY ACCOUNT") }}</p>
      <ul class="list">
        <li>
          <router-link to="./account" class="link-over">
            {{ $t("ACCOUNT") }}
          </router-link>
        </li>
        <li>
          <router-link to="./referral" class="link-over">
            {{ $t("REFERRAL CODE") }}
          </router-link>
        </li>
        <li>
          <router-link to="./deposit" class="link-over">
            {{ $t("DEPOSIT (before)") }}
          </router-link>
        </li>
        <li>
          <router-link to="./deposit" class="link-over">
            {{ $t("DEPOSIT (after)") }}
          </router-link>
        </li>
        <li>
          <router-link to="./withdraw" class="link-over">{{
            $t("WITHDRAW")
          }}</router-link>
        </li>
        <li>
          <router-link to="./plan" class="link-over"> プラン購入 </router-link>
        </li>
        <li>
          <router-link to="./history" class="link-over">{{
            $t("HISTORY")
          }}</router-link>
        </li>
        <!--<li>-->
        <!--<router-link to="./organization" class="link-over"-->
        <!--&gt;{{ $t("Organization") }} テーブル</router-link-->
        <!--&gt;-->
        <!--</li>-->
        <li>
          <router-link to="./organizationchart" class="link-over"
            >{{ $t("Organization") }} チャート</router-link
          >
        </li>
        <li>
          <router-link to="./transfer" class="link-over">{{
            $t("TRANSFER")
          }}</router-link>
        </li>
      </ul>
      <p>{{ $t("INFORMATION") }}</p>
      <ul class="list">
        <li>
          <router-link to="#" class="link-over">
            {{ $t("MESSAGE") }}
          </router-link>
        </li>
        <li>
          <router-link to="#" class="link-over">
            {{ $t("FAQ") }}
          </router-link>
        </li>
        <li>
          <router-link to="#" class="link-over">
            {{ $t("CONTACT") }}
          </router-link>
        </li>
      </ul>
      <p>{{ $t("BEFORE USING") }}</p>
      <ul class="list">
        <li>
          <router-link to="#" class="link-over">
            {{ $t("PRIVACY POLICY") }}
          </router-link>
        </li>
        <li>
          <router-link to="#" class="link-over">
            {{ $t("TERMS OF USE") }}
          </router-link>
        </li>
      </ul>
      <ul class="list">
        <li>
          <a class="link-over" @click="loggedOut">
            {{ $t("SIGN OUT") }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Common from "@/js/common";
export default {
  name: "Sidebar",
  props: {},
  methods: {
    loggedOut() {
      Common.loggedOut();
    },
  },
};
</script>

<style scoped lang="less"></style>
