<template>
  <div class="wrapper forgot-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <h1>{{ $t("Reset Password") }}</h1>
          <div class="form">
            <form>
              <div class="input-area">
                <label class="label" for="password">
                  <input
                    type="text"
                    name=""
                    id="password"
                    placeholder=" "
                    v-model="newPassword"
                  />
                  <span class="group-label">{{
                    $t("Enter new password")
                  }}</span>
                </label>
                <span class="circle" v-bind:class="{ done: hasNewPassword }">
                  <span class="bar"></span>
                </span>
              </div>
              <div class="input-area">
                <label class="label" for="c-password">
                  <input
                    type="text"
                    name=""
                    id="c-password"
                    placeholder=" "
                    v-model="newConfirmPassword"
                  />
                  <span class="group-label">{{
                    $t("Enter password again")
                  }}</span>
                </label>
                <span
                  class="circle"
                  v-bind:class="{
                    done: hasNewConfirmPassword,
                  }"
                >
                  <span class="bar"></span>
                </span>
              </div>
              <div class="btn">
                <button
                  type="button"
                  class="link-over"
                  v-bind:disabled="!hasBothPassword"
                  v-on:click="toSubmitPassword"
                >
                  {{ $t("Complete the settings") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <Footer title="Forgot" />
  </div>
</template>

<style lang="less" scoped>
.header {
  :deep(.grade) {
    display: none !important;
  }
  :deep(.detail) {
    display: none !important;
  }
}
.forgot-page {
  :deep(.footer) {
    padding-bottom: 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 9;
    .footer-fixed-menu {
      display: none;
    }
    .page-nav {
      display: none;
    }
    .language {
      .logo {
        img {
          display: none;
        }
      }
    }
  }
}
</style>

<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Forgot",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      title: "Forgot",
      newPassword: "",
      newConfirmPassword: "",
    };
  },
  methods: {
    toSubmitPassword() {
      this.$router.push("/donepassword");
    },
  },
  computed: {
    hasNewPassword() {
      return this.newPassword;
    },
    hasNewConfirmPassword() {
      return this.newConfirmPassword;
    },
    hasBothPassword() {
      return this.newPassword && this.newConfirmPassword;
    },
  },
  mounted: function () {},
};
</script>
