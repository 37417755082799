<template>
  <div class="wrapper dashboard-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left"></div>
        <div class="right select-area">
          <div class="txt">
            <span v-if="userInfo.isRePlate">
              {{ $t("If your status is longer than 30 days,") }}
            </span>
            <span v-else>
              {{ $t("If your binding is longer than 365 days,") }}
            </span>
            <br />
            <span>{{ $t("Or binding again?") }}</span
            ><br />
            <span>{{ $t("Please withdraw your money") }}</span>
          </div>
          <div class="package">
            <div class="btn green">
              <router-link to="./staking" class="link-over">{{
                $t("RESAKING")
              }}</router-link>
            </div>
            <div class="btn white">
              <router-link to="./withdraw2" class="link-over">{{
                $t("Pay")
              }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->
  </div>
</template>
<style scoped></style>
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
export default {
  name: "ReStaking",
  components: {
    Header,
    HeaderMenu,
  },
  data: () => {
    return {
      changeColor: false,
      amount: "",
      isActive: "1",
      newsFlag: true,
      userInfo: {},
    };
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  },
  methods: {
    isSelect: function (num) {
      this.isActive = num;
    },
    show2: function () {
      this.$modal.show("noticeModal");
    },
    hide2: function () {
      this.$modal.hide("noticeModal");
    },
  },

  computed: {
    addClass() {
      if (this.amount !== "") {
        return "done";
      } else {
        return "";
      }
    },
  },
  mounted: function () {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", vh + "px");

    var ua = navigator.userAgent.toLowerCase();
    var isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
    if (isiOS) {
      var viewport = document.querySelector('meta[name="viewport"]');
      if (viewport) {
        var viewportContent = viewport.getAttribute("content");
        viewport.setAttribute(
          "content",
          viewportContent + ", user-scalable=no"
        );
      }
    }
  },
};
</script>
