<template>
  <div>
    <router-view />
  </div>
</template>

<style>
@import "./assets/style/style.css";
.rc-anchor-normal-footer {
  display: none !important;
}
.grecaptcha-badge {
  visibility: hidden;
}
</style>
