import Fetch from "@/js/api/fetch";

const Asset = {
  getHistories: (data) =>
    Fetch.get("/Asset/ListPointHistories", {
      params: data,
    }),
  pointToUser: (data) => Fetch.post("/Asset/PointToUser", data),
  pointToPoint: (data) => Fetch.post("/Asset/PointToPoint", data),
  getDepositAndWithdrawals: (data) =>
    Fetch.get("/Asset/ListDepositAndWithdrawals", {
      params: data,
    }),
};

export default Asset;
