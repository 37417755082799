<template>
  <div class="wrapper forgot-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <div class="txt-area">
            <p>
              {{ $t("To the entered address") }}<br />{{
                $t("URL for password reset")
              }}<br />{{ $t("Sent.") }}
            </p>
            <div class="btn">
              <router-link to="./resetpassword" class="link-over">{{
                $t("SIGN IN")
              }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <Footer title="Forgot" />
  </div>
</template>

<style lang="less" scoped>
.header {
  :deep(.grade) {
    display: none !important;
  }
  :deep(.detail) {
    display: none !important;
  }
}
.forgot-page {
  :deep(.footer) {
    padding-bottom: 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 9;
    .footer-fixed-menu {
      display: none;
    }
    .page-nav {
      display: none;
    }
    .language {
      .logo {
        img {
          display: none;
        }
      }
    }
  }
}
</style>

<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Forgot",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      title: "Forgot",
    };
  },
  methods: {},
  computed: {},
  mounted: function () {},
};
</script>
