<template>
  <div class="wrapper forgot-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <div class="tab-area">
            <!-- <div
              class="tab link-over"
              v-on:click="isSelect('1')"
              v-bind:class="{ active: isActive === '1' }"
              style="pointer-events: none"
            >
              {{ $t("Username") }}
            </div> -->
            <div
              class="tab link-over"
              v-on:click="isSelect('2')"
              v-bind:class="{ active: isActive === '2' }"
            >
              {{ $t("PASSWORD") }}
            </div>
          </div>
          <div
            class="content-area"
            v-show="isActive === '1'"
            v-bind:class="{ active: isActive === '1' }"
          ></div>
          <div
            class="content-area"
            v-show="isActive === '2'"
            v-bind:class="{ active: isActive === '2' }"
          >
            <div class="form">
              <form>
                <div class="input-area">
                  <label class="label" for="mail">
                    <input type="text" placeholder=" " v-model="userEmail" />
                    <span class="group-label">{{
                      $t("Enter your email address")
                    }}</span>
                  </label>
                  <span class="circle" v-bind:class="{ done: hasUserEmail }">
                    <span class="bar"></span>
                  </span>
                </div>
                <div class="input-area">
                  <label class="label" for="mail">
                    <input
                      type="text"
                      placeholder=" "
                      v-model="verificationCode"
                    />
                    <span class="group-label">{{
                      $t("Enter your verification code")
                    }}</span>
                  </label>
                  <van-button
                    native-type="button"
                    size="mini"
                    class="link-over edit"
                    :loading="loading"
                    @click="sendCode"
                  >
                    {{ $t("Send") }}
                  </van-button>
                  <span
                    class="circle"
                    v-bind:class="{ done: hasVerificationCode }"
                  >
                    <span class="bar"></span>
                  </span>
                </div>
                <div class="input-area">
                  <label class="label" for="password">
                    <input
                      type="password"
                      placeholder=" "
                      id="password"
                      v-model="password"
                    />
                    <span class="group-label">{{
                      $t("Enter new password")
                    }}</span>
                  </label>
                  <span class="circle" v-bind:class="{ done: hasNewPassword }">
                    <span class="bar"></span>
                  </span>
                </div>
                <div class="input-area">
                  <label class="label" for="c-password">
                    <input
                      type="password"
                      placeholder=" "
                      id="c-password"
                      v-model="confirmPassword"
                    />
                    <span class="group-label">{{
                      $t("Enter password again")
                    }}</span>
                  </label>
                  <span
                    class="circle"
                    v-bind:class="{
                      done: hasNewConfirmPassword,
                    }"
                  >
                    <span class="bar"></span>
                  </span>
                </div>
                <div class="btn">
                  <van-button
                    type="button"
                    class="link-over"
                    :loading="loading"
                    v-bind:disabled="!hasInputAll"
                    v-on:click="submitData"
                  >
                    {{ $t("Complete the settings") }}
                  </van-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <Footer title="Forgot" />
  </div>
</template>

<style lang="less" scoped>
.forgot-page .form form .input-area button.edit {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  background-color: #3e6cf2;
  color: #fff;
  font-size: 10px;
  padding: 4px 12px;
  line-height: 1;
  top: 50%;
  right: 14%;
  transform: translateY(-50%) translateX(0%);
  border-radius: 10px;
}

.forgot-page .tab-area .tab {
  width: 100%;
}

.header {
  :deep(.grade) {
    display: none !important;
  }
  :deep(.detail) {
    display: none !important;
  }
  :deep(.menu) {
    display: none !important;
  }
}
.forgot-page {
  :deep(.footer) {
    padding-bottom: 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 9;
    .footer-fixed-menu {
      display: none;
    }
    .page-nav {
      display: none;
    }
    .language {
      .logo {
        img {
          display: none;
        }
      }
    }
  }
}
</style>

<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
import Account from "@/js/api/account";
import { Notify } from "vant";
export default {
  name: "Forgot",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      title: "Forgot",
      isActive: "2",
      userName: "",
      userEmail: "",
      verificationCode: "",
      password: "",
      confirmPassword: "",
      loading: false,
    };
  },
  mounted: function () {
    this.init();
  },
  methods: {
    init: function () {
      if (this.$route.query.username) {
        this.userName = this.$route.query.username;
      }
    },
    sendCode: function () {
      if (this.userEmail) {
        this.loading = true;
        let params = {};
        params.userName = this.userName;
        params.email = this.userEmail;
        Account.validateEmailAndUserName(params).then((res) => {
          this.loading = false;
          if (res) {
            this.loading = true;
            Account.sendResetPasswordCodeByEmail(this.userEmail).then((res) => {
              this.loading = false;
              if (res) {
                Notify({
                  type: "success",
                  message: this.$t(
                    "Verification code has been sent to your email"
                  ),
                });
              }
            });
          }
        });
      } else {
        Notify({
          type: "danger",
          message: this.$t("{item} is required.", {
            item: this.$t("YOUR EMAIL"),
          }),
        });
      }
    },
    submitData: function () {
      this.loading = true;
      Account.resetPassword({
        email: this.userEmail,
        verificationCode: this.verificationCode,
        newPassword: this.password,
        confirmPassword: this.confirmPassword,
      })
        .then((response) => {
          if (response) {
            Notify({ type: "success", message: "success" });
            this.$router.push("/signin");
          }
        })
        .finally(() => (this.loading = false));
    },
    isSelect: function (num) {
      this.isActive = num;
    },
    toSendPassword() {
      this.$router.push("/forgotpassword2");
    },
  },
  computed: {
    hasInputAll() {
      return (
        this.password &&
        this.confirmPassword &&
        this.verificationCode &&
        this.userEmail
      );
    },
    hasVerificationCode() {
      return this.verificationCode;
    },
    hasUserName() {
      return this.userName;
    },
    hasUserEmail() {
      return this.userEmail;
    },
    hasNewPassword() {
      return this.password;
    },
    hasNewConfirmPassword() {
      return this.confirmPassword;
    },
  },
};
</script>
