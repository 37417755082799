import Fetch from "@/js/api/fetch";

const Notice = {
  getNoticesAsync: (data) =>
    Fetch.get("/Notice/GetNotice", {
      params: data,
    }),

  getRecentlyNoticesAsync: (data) =>
    Fetch.get("/Notice/getRecentlyNotices", {
      params: data,
    }),
  listNoticeAsync: (data) =>
    Fetch.get("/Notice/ListNotice", {
      params: data,
    }),
  isHaveNewNoticeAsync: () => Fetch.get("/Notice/IsHaveNewNotice"),

  insertUserReadAsync: (id) =>
    Fetch.post(`/Notice/InsertUserRead?noticeId=${id}`),
  postInquiryAsync: (data) => Fetch.post(`/Notice/CreateInquiry`, data),

  // getInquiryAsync: () => Fetch.get("/Inquiry"),
  //
};

export default Notice;
