<template>
  <div class="wrapper forgot-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <div class="tab-area">
            <!-- <div
              class="tab link-over"
              v-on:click="isSelect('1')"
              v-bind:class="{ active: isActive === '1' }"
            >
              {{ $t("Username") }}
            </div> -->
            <div
              class="tab link-over"
              v-on:click="isSelect('2')"
              v-bind:class="{ active: isActive === '2' }"
            >
              {{ $t("PASSWORD") }}
            </div>
          </div>
          <div
            class="content-area"
            v-show="isActive === '1'"
            v-bind:class="{ active: isActive === '1' }"
          >
            <div class="form">
              <form>
                <div class="input-area">
                  <label class="label" for="mail">
                    <input
                      type="text"
                      name=""
                      id="mail"
                      placeholder=" "
                      v-model="userEmail"
                    />
                    <span class="group-label">{{
                      $t("Enter your email address")
                    }}</span>
                  </label>
                  <span class="circle" v-bind:class="{ done: hasUserEmail }">
                    <span class="bar"></span>
                  </span>
                </div>
                <p class="vali-message">
                  {{ $t("The email address is different.") }}
                </p>
                <div class="btn">
                  <button
                    type="button"
                    class="link-over"
                    v-bind:disabled="!hasUserEmail"
                    v-on:click="toUsername"
                  >
                    {{ $t("Next") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div
            class="content-area"
            v-show="isActive === '2'"
            v-bind:class="{ active: isActive === '2' }"
          >
            <div class="form">
              <form>
                <div class="input-area">
                  <label class="label" for="user">
                    <input
                      type="text"
                      name=""
                      id="user"
                      placeholder=" "
                      v-model="userName"
                    />
                    <span class="group-label">{{ $t("YOUR USER NAME") }}</span>
                  </label>
                  <span class="circle" v-bind:class="{ done: hasUserName }">
                    <span class="bar"></span>
                  </span>
                </div>
                <p class="vali-message" v-show="userNameIsError">
                  {{ $t("USER DOES NOT EXIST") }}
                </p>
                <div class="btn">
                  <button
                    type="button"
                    class="link-over"
                    v-bind:disabled="!hasUserName"
                    v-on:click="toPassword"
                  >
                    {{ $t("Next") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <Footer title="Forgot" />
  </div>
</template>

<style lang="less" scoped>
.forgot-page .tab-area .tab {
  width: 100%;
}
.header {
  :deep(.grade) {
    display: none !important;
  }
  :deep(.detail) {
    display: none !important;
  }
  :deep(.menu) {
    display: none !important;
  }
}
.forgot-page {
  :deep(.footer) {
    padding-bottom: 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 9;
    .footer-fixed-menu {
      display: none;
    }
    .page-nav {
      display: none;
    }
    .language {
      .logo {
        img {
          display: none;
        }
      }
    }
  }
}
</style>

<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
import Account from "@/js/api/account";
import { Notify } from "vant";
export default {
  name: "Forgot",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      title: "Forgot",
      isActive: "2",
      userName: "",
      userEmail: "",
      userNameIsError: false,
    };
  },
  watch: {
    userName: function () {
      this.userNameIsError = false;
    },
  },
  methods: {
    isSelect: function (num) {
      this.isActive = num;
    },
    toPassword() {
      if (this.userName) {
        this.loading = true;
        Account.getExistedUser(this.userName).then((res) => {
          this.loading = false;
          if (res) {
            if (res.data) {
              this.$router.push({
                path: "forgotpassword",
                query: {
                  username: this.userName,
                },
              });
            } else {
              this.userNameIsError = true;
            }
          }
        });
      } else {
        Notify({
          type: "danger",
          message: this.$t("{item} is required.", {
            item: this.$t("YOUR USER NAME"),
          }),
        });
      }
    },
    toUsername() {
      this.$router.push("/forgotaccount");
    },
  },
  computed: {
    hasUserName() {
      return this.userName;
    },
    hasUserEmail() {
      return this.userEmail;
    },
  },
  mounted: function () {},
};
</script>
