<template>
  <div class="wrapper forgot-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <div class="tab-area">
            <div
              class="tab link-over"
              v-on:click="isSelect('1')"
              v-bind:class="{ active: isActive === '1' }"
            >
              {{ $t("Username") }}
            </div>
            <div
              class="tab link-over"
              v-on:click="isSelect('2')"
              v-bind:class="{ active: isActive === '2' }"
              style="pointer-events: none"
            >
              {{ $t("PASSWORD") }}
            </div>
          </div>
          <div
            class="content-area"
            v-show="isActive === '1'"
            v-bind:class="{ active: isActive === '1' }"
          >
            <div class="form">
              <form>
                <div class="input-area">
                  <label class="label" for="password">
                    <input
                      type="password"
                      name=""
                      id="password"
                      placeholder=" "
                      v-model="userPassword"
                    />
                    <span class="group-label">{{ $t("Enter Password") }}</span>
                  </label>
                  <span class="circle" v-bind:class="{ done: hasUserPassword }">
                    <span class="bar"></span>
                  </span>
                </div>
                <p class="vali-message">
                  {{ $t("Password is incorrect.") }}
                </p>
                <div class="btn">
                  <button
                    type="button"
                    class="link-over"
                    v-bind:disabled="!hasUserPassword"
                    v-on:click="toSendPassword"
                  >
                    {{ $t("Next") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div
            class="content-area"
            v-show="isActive === '2'"
            v-bind:class="{ active: isActive === '2' }"
          ></div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <Footer title="Forgot" />
  </div>
</template>

<style lang="less" scoped>
.header {
  :deep(.grade) {
    display: none !important;
  }
  :deep(.detail) {
    display: none !important;
  }
}
.forgot-page {
  :deep(.footer) {
    padding-bottom: 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 9;
    .footer-fixed-menu {
      display: none;
    }
    .page-nav {
      display: none;
    }
    .language {
      .logo {
        img {
          display: none;
        }
      }
    }
  }
}
</style>

<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Forgot",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      title: "Forgot",
      isActive: "1",
      userPassword: "",
    };
  },
  methods: {
    isSelect: function (num) {
      this.isActive = num;
    },
    toSendPassword() {
      this.$router.push("/doneaccount");
    },
  },
  computed: {
    hasUserPassword() {
      return this.userPassword;
    },
  },
  mounted: function () {},
};
</script>
