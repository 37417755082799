<template>
  <div class="wrapper history-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="pc-only main">
          <h1>{{ $t("HISTORY") }}</h1>
        </div>
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <div class="sp-only">
            <h1>{{ $t("HISTORY") }}</h1>
          </div>
          <div class="tab-area">
            <div
              class="tab link-over"
              v-on:click="isSelect('1')"
              v-bind:class="{ active: isActive === '1' }"
            >
              {{ $t("Bonus") }}
            </div>
            <div
              class="tab link-over"
              v-on:click="isSelect('2')"
              v-bind:class="{ active: isActive === '2' }"
            >
              {{ $t("D&W") }}
            </div>
            <div
              class="tab link-over"
              v-on:click="isSelect('3')"
              v-bind:class="{ active: isActive === '3' }"
            >
              {{ $t("BUYPLAN") }}
            </div>
            <div
              class="tab link-over"
              v-on:click="isSelect('4')"
              v-bind:class="{ active: isActive === '4' }"
            >
              {{ $t("STAKING") }}
            </div>
            <div
              class="tab link-over"
              v-on:click="isSelect('5')"
              v-bind:class="{ active: isActive === '5' }"
            >
              {{ $t("PP") }}
            </div>
          </div>
          <div
            class="content-area"
            v-show="isActive === '1'"
            v-bind:class="{ active: isActive === '1' }"
          >
            <div class="table">
              <h2>{{ $t("{item} HISTORY", { item: $t("BONUS") }) }}</h2>
              <table>
                <tr>
                  <th>{{ $t("DATE") }}</th>
                  <th>{{ $t("CURRENCY") }}</th>
                  <th>{{ $t("AMOUNT") }}</th>
                  <th>{{ $t("TYPE") }}</th>
                </tr>
                <template v-if="tableList.length > 0">
                  <tr v-for="(item, index) in tableList" :key="index">
                    <td>{{ item.createdTime }}</td>
                    <td>{{ item.assetName }}</td>
                    <td>{{ item.pointsText }}</td>
                    <td>{{ item.assetHistoryText }}</td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="4">{{ $t("NO DATA") }}</td>
                </tr>
              </table>
              <van-pagination
                v-if="total > 4"
                v-model="currentPage"
                :total-items="total"
                :show-page-size="3"
                :items-per-page="4"
                @change="onPage"
                force-ellipses
              >
                <template #prev-text>
                  {{ $t("PREVIOUS") }}
                </template>
                <template #next-text>
                  {{ $t("NEXT") }}
                </template>
              </van-pagination>
            </div>
          </div>
          <div
            class="content-area"
            v-show="isActive === '2'"
            v-bind:class="{ active: isActive === '2' }"
          >
            <div class="table">
              <h2>
                {{ $t("{item} HISTORY", { item: $t("DEPOSIT & WITHDRAWAL") }) }}
              </h2>
              <table>
                <tr>
                  <th>{{ $t("DATE") }}</th>
                  <th>{{ $t("CURRENCY") }}</th>
                  <th>{{ $t("AMOUNT") }}</th>
                  <th>{{ $t("TYPE") }}</th>
                </tr>
                <template v-if="tableList.length > 0">
                  <tr v-for="(item, index) in tableList" :key="index">
                    <td>{{ item.createdTime }}</td>
                    <td>{{ item.assetName }}</td>
                    <td>{{ item.pointsText }}&nbsp;</td>
                    <td>{{ item.assetHistoryText }}</td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="4">{{ $t("NO DATA") }}</td>
                </tr>
              </table>
              <van-pagination
                v-if="total > 4"
                v-model="currentPage"
                :total-items="total"
                :show-page-size="3"
                :items-per-page="4"
                @change="onPage"
                force-ellipses
              >
                <template #prev-text>
                  {{ $t("PREVIOUS") }}
                </template>
                <template #next-text>
                  {{ $t("NEXT") }}
                </template>
              </van-pagination>
            </div>
          </div>
          <div
            class="content-area"
            v-show="isActive === '3'"
            v-bind:class="{ active: isActive === '3' }"
          >
            <div class="table">
              <h2>
                <h2>{{ $t("{item} HISTORY", { item: $t("BUYPLAN") }) }}</h2>
              </h2>
              <table>
                <tr>
                  <th>{{ $t("DATE") }}</th>
                  <th>{{ $t("CURRENCY") }}</th>
                  <th>{{ $t("PLAN") }}</th>
                  <th>{{ $t("POINT AMOUNT") }}</th>
                </tr>
                <template v-if="tableList.length > 0">
                  <tr v-for="(item, index) in tableList" :key="index">
                    <td>{{ item.createdTime }}</td>
                    <td>{{ item.currency }}</td>
                    <td>{{ item.bonusSettingText }}</td>
                    <td>{{ item.pointsText }}&nbsp;</td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="4">{{ $t("NO DATA") }}</td>
                </tr>
              </table>
              <van-pagination
                v-if="total > 4"
                v-model="currentPage"
                :total-items="total"
                :show-page-size="3"
                :items-per-page="4"
                @change="onPage"
                force-ellipses
              >
                <template #prev-text>
                  {{ $t("PREVIOUS") }}
                </template>
                <template #next-text>
                  {{ $t("NEXT") }}
                </template>
              </van-pagination>
            </div>
          </div>
          <div
            class="content-area"
            v-show="isActive === '4'"
            v-bind:class="{ active: isActive === '4' }"
          >
            <div class="table">
              <h2>
                {{ $t("{item} HISTORY", { item: $t("STAKING REWARD") }) }}
              </h2>
              <table>
                <tr>
                  <th>{{ $t("DATE") }}</th>
                  <th>{{ $t("POINT") }}</th>
                  <th>{{ $t("REWARD") }}</th>
                </tr>
                <template v-if="tableList.length > 0">
                  <tr v-for="(item, index) in tableList" :key="index">
                    <td>{{ item.createdTime }}</td>
                    <td>{{ item.assetName }}</td>
                    <td>{{ item.pointsText }}&nbsp;</td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="4">{{ $t("NO DATA") }}</td>
                </tr>
              </table>
              <van-pagination
                v-if="total > 4"
                v-model="currentPage"
                :total-items="total"
                :show-page-size="3"
                :items-per-page="4"
                @change="onPage"
                force-ellipses
              >
                <template #prev-text>
                  {{ $t("PREVIOUS") }}
                </template>
                <template #next-text>
                  {{ $t("NEXT") }}
                </template>
              </van-pagination>
            </div>
          </div>
          <div
            class="content-area"
            v-show="isActive === '5'"
            v-bind:class="{ active: isActive === '5' }"
          >
            <div class="table">
              <h2>
                {{ $t("{item} HISTORY", { item: $t("PP") }) }}
              </h2>
              <table>
                <tr>
                  <th>{{ $t("DATE") }}</th>
                  <th>{{ $t("POINT") }}</th>
                  <th>{{ $t("WITHDRAWAL AMOUNT") }}</th>
                  <th>{{ $t("BALANCE") }}</th>
                </tr>
                <template v-if="tableList.length > 0">
                  <tr v-for="(item, index) in tableList" :key="index">
                    <td>{{ item.createdTime }}</td>
                    <td>{{ item.assetHistoryText }}</td>
                    <td>{{ item.pointsText }}&nbsp;</td>
                    <td>{{ item.after }}</td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="4">{{ $t("NO DATA") }}</td>
                </tr>
              </table>
              <van-pagination
                v-if="total > 4"
                v-model="currentPage"
                :total-items="total"
                :show-page-size="3"
                :items-per-page="4"
                @change="onPage"
                force-ellipses
              >
                <template #prev-text>
                  {{ $t("PREVIOUS") }}
                </template>
                <template #next-text>
                  {{ $t("NEXT") }}
                </template>
              </van-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <Footer :title="$t('HISTORY')" />
  </div>
</template>
<style scoped></style>
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
import Asset from "@/js/api/asset.js";
export default {
  name: "History",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      title: "History",
      isActive: "1",
      currentPage: 1,
      total: 0,
      tableList: [],
    };
  },
  created() {
    this.getHistories();
  },
  methods: {
    isSelect: function (num) {
      this.isActive = num;
      this.currentPage = 1;
      if (this.isActive === "2") {
        this.getDepositAndWithdrawals();
      } else {
        this.getHistories();
      }
    },
    onPage(val) {
      this.currentPage = val;
      if (this.isActive === "2") {
        this.getDepositAndWithdrawals();
      } else {
        this.getHistories();
      }
    },
    getHistories() {
      let data = {
        index: this.currentPage - 1,
        take: 4,
      };
      if (this.isActive === "1") {
        data.range = "AllProfit";
      } else if (this.isActive === "3") {
        data.historyType = "PlateActive";
        data.assetType = "PP";
      } else if (this.isActive === "4") {
        data.assetType = "STANDARDTOKEN";
        data.range = "Reward";
      } else if (this.isActive === "5") {
        data.assetType = "SP";
      }
      Asset.getHistories(data).then((res) => {
        if (res) {
          this.tableList = res.data.detail;
          this.total = res.data.total;
        }
      });
    },
    getDepositAndWithdrawals() {
      let data = {
        index: this.currentPage - 1,
        take: 4,
      };
      Asset.getDepositAndWithdrawals(data).then((res) => {
        if (res) {
          this.tableList = res.data.detail;
          this.total = res.data.total;
        }
      });
    },
  },
  computed: {},
  mounted: function () {},
};
</script>
