<template>
  <div class="wrapper signup-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left"></div>
        <div class="right">
          <h1>
            {{ $t("E-MAIL ADDRESS AUTHENTICATION") }}
          </h1>
          <div class="c-formContainer">
            <!-- last -->
            <div class="btn">
              <form class="c-form" action="">
                <!-- 1 -->
                <div class="c-form__group active">
                  <p class="code-msg">
                    {{
                      $t(
                        "Please enter the authentication code sent to your email address below."
                      )
                    }}
                  </p>
                  <label class="c-form__label" for="email">
                    <input
                      type="text"
                      id="email"
                      class="c-form__input"
                      v-model="verificationCode"
                      placeholder=" "
                      required
                    />
                    <label
                      class="c-form__next"
                      for=""
                      role="button"
                      v-on:click="toBtn()"
                    >
                      <span class="c-form__nextIcon">
                        <img src="../assets/img/signin-arrow-img.svg" alt="" />
                      </span>
                    </label>

                    <span class="c-form__groupLabel">{{
                      $t("Enter confirmation code")
                    }}</span>
                    <b class="c-form__border"></b>
                  </label>
                  <button
                    class="return-txt"
                    type="button"
                    v-on:click="toReturnBtn()"
                  >
                    ◀︎ Back
                  </button>
                </div>
                <!-- ./1 -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <Footer title="ChangeEmail" />
  </div>
</template>

<style lang="less" scoped>
.header {
  /deep/ .grade {
    display: none !important;
  }
  /deep/ .detail {
    display: none !important;
  }
}
.signup-page {
  /deep/ .fix-bar {
    padding: 60px 0 80px;
  }
  /deep/ .footer {
    padding-bottom: 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 9;
    .footer-fixed-menu {
      display: none;
    }
    .page-nav {
      display: none;
    }
    .language {
      .logo {
        img {
          display: none;
        }
      }
      .select-box {
        @media screen and (min-width: 767px) {
          margin-top: 0;
        }
      }
    }
  }
}
</style>

<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Footer from "@/components/Footer.vue";
import Account from "@/js/api/account";
import { Notify } from "vant";
export default {
  name: "ChangeEmail",
  components: {
    Header,
    HeaderMenu,
    Footer,
  },
  data: () => {
    return {
      userCheck: "",
      userCheck2: "",
      email: "",
      verificationCode: "",
    };
  },
  methods: {
    toBtn() {
      if (this.verificationCode != "") {
        this.email = this.$route.query.email;
        Account.changeEmailAsync(this.email, this.verificationCode).then(() => {
          this.$router.push("/changeemail3");
        });
      } else {
        Notify({
          type: "danger",
          message: this.$t("{item} is required.", {
            item: this.$t("verificationCode"),
          }),
        });
      }
    },
    toReturnBtn() {
      this.$router.push("/changeemail");
    },
  },
  computed: {},
  mounted: function () {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", vh + "px");

    var ua = navigator.userAgent.toLowerCase();
    var isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
    if (isiOS) {
      var viewport = document.querySelector('meta[name="viewport"]');
      if (viewport) {
        var viewportContent = viewport.getAttribute("content");
        viewport.setAttribute(
          "content",
          viewportContent + ", user-scalable=no"
        );
      }
    }
  },
};
</script>
