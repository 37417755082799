<template>
  <div class="wrapper staking-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <div class="balance">
            <h2>{{ $t("Possible cost") }}</h2>
            <div class="total-amount">
              <span>{{ totalAp }}</span> AP
            </div>
            <ul class="list">
              <li>
                <h3>AP</h3>
                <div class="amount">
                  <span>{{ plateInfo.fp }}</span>
                </div>
              </li>
              <li>
                <h3>{{ $t("AP Reward") }}</h3>
                <div class="amount">
                  <span>{{ plateInfo.fp2 }}</span>
                </div>
              </li>
            </ul>
          </div>
          <div class="select-box">
            <div class="input-area">
              <input type="radio" name="plan" id="staking1" checked />
              <label for="staking1" class="link-over" v-on:click="isSelect(0)">
                {{ $t("30 days (full amount)") }}
                <div class="circle">
                  <img src="../assets/img/check-icon-img.png" />
                </div>
              </label>
            </div>
            <div class="input-area">
              <input type="radio" name="plan" id="staking2" />
              <label for="staking2" class="link-over" v-on:click="isSelect(1)">
                {{ $t("30 days (AP only)") }}
                <div class="circle">
                  <img src="../assets/img/check-icon-img.png" />
                </div>
              </label>
            </div>
          </div>
          <div class="staking-box" v-show="active.radio === 0">
            <div class="amount">
              <p>{{ $t("Sticking amount") }}</p>
              <div class="number">
                <span>{{ totalAp }}</span> AP
              </div>
            </div>
            <div class="btn">
              <button
                type="button"
                class="link-over"
                v-on:click="show.allStaking = true"
              >
                {{ $t("Staking") }}
              </button>
            </div>
          </div>
          <div class="staking-box" v-show="active.radio === 1">
            <div class="amount">
              <p>{{ $t("Sticking amount") }}</p>
              <div class="number">
                <span>{{ plateInfo.fp }}</span> AP
              </div>
            </div>
            <div class="input">
              <p>{{ $t("Source of AP compensation") }}</p>
              <div class="input-area">
                <label class="label" for="address">
                  <input
                    type="text"
                    name=""
                    id="address"
                    placeholder=" "
                    v-model="stakingForm.address"
                  />
                  <span class="group-label">{{
                    $t("Enter the wallet address of azero.")
                  }}</span>
                </label>
                <span class="circle" v-bind:class="{ done: hasAddress }">
                  <span class="bar"></span>
                </span>
              </div>
              <div class="txt">
                <p>{{ $t("※ the payment fee is 10%.") }}</p>
              </div>
            </div>
            <div class="all-amount">
              <p>{{ $t("Amount to be withdrawn") }}</p>
              <div class="number">
                <span>{{
                  (Number(plateInfo.fp2) - Number(plateInfo.fp2) * 0.1).toFixed(
                    3
                  )
                }}</span>
                AP
              </div>
              <p class="notice">
                （{{
                  $t("Fees:") + (Number(plateInfo.fp2) * 0.1).toFixed(3)
                }}AP）
              </p>
            </div>
            <div class="btn">
              <button
                type="button"
                class="link-over"
                v-on:click="show.staking = true"
              >
                {{ $t("Staking") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <van-popup v-model:show="show.allStaking" style="width: 90%">
      <div class="modal-box">
        <div class="modal-ttl">
          <p>
            {{ $t("AP + AP rewards") }}<br />{{
              $t("Would you like to re stay?")
            }}
          </p>
        </div>
        <div class="btn">
          <van-button
            v-on:click="stakingSubmit"
            :loading="loading.staking"
            type="button"
            class="link-over"
            style="height: 54px"
          >
            {{ $t("Complete") }}
          </van-button>
        </div>
      </div>
    </van-popup>

    <van-popup v-model:show="show.staking" style="width: 90%">
      <div class="modal-box">
        <div class="modal-ttl">
          <p>{{ $t("AP") }}<br />{{ $t("Would you like to re stay?") }}</p>
          <p>（{{ $t("AP reward application") }}）</p>
        </div>
        <div class="btn">
          <van-button
            v-on:click="stakingSubmit"
            :loading="loading.staking"
            type="button"
            class="link-over"
            style="height: 54px"
          >
            {{ $t("Complete") }}
          </van-button>
        </div>
      </div>
    </van-popup>

    <Footer title="Staking" />
  </div>
</template>
<style scoped></style>
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
import Plate from "../js/api/plate";
import Common from "../js/common";
import { i18n } from "../js/i18n/i18n";
import LoginUser from "@/js/loginUser.js";
import Definitions from "@/js/definitions";
export default {
  name: "Staking",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      isActive: "1",
      address: "",
      amountAp: 100,
      active: {
        radio: 0,
      },
      show: {
        allStaking: false,
        staking: false,
      },
      stakingForm: {
        amount: "",
        address: "",
      },
      loading: {
        staking: false,
      },
    };
  },

  methods: {
    isSelect: function (num) {
      this.active.radio = num;
    },
    stakingSubmit() {
      if (this.active.radio == null) {
        Common.warning(i18n.global.t("Please select a staking option."));
      } else if (this.active.radio == 1 && this.stakingForm.address == "") {
        Common.warning(i18n.global.t("Please enter address."));
      } else {
        this.loading.staking = true;
        const params = {
          isAll: this.active.radio == 0,
          address: this.stakingForm.address,
        };
        Plate.ReStaking(params)
          .then(() => {
            Common.success(i18n.global.t("Pledge succeeded."));
            this.getPlateInfoAsync();
          })
          .catch(() => {
            this.loading.staking = false;
          });
      }
    },
    getPlateInfoAsync() {
      if (
        !Common.isEmpty(
          localStorage.getItem(Definitions.StorageKeys.accessToken)
        )
      ) {
        this.userTitle = LoginUser.getUserTitle();
        Plate.getPlateInfo().then((res) => {
          if (res) {
            this.$store.commit("setPlateInfo", res.data);
            this.ap = res.data.ap;
            this.$router.push({ name: "Dashboard" });
          }
        });
      }
    },
  },
  computed: {
    hasAddress() {
      return this.address;
    },
    plateInfo() {
      return this.$store.getters.getPlateInfo;
    },
    totalAp() {
      return Number(this.plateInfo.fp) + Number(this.plateInfo.fp2);
    },
  },
  mounted: function () {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", vh + "px");

    var ua = navigator.userAgent.toLowerCase();
    var isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
    if (isiOS) {
      var viewport = document.querySelector('meta[name="viewport"]');
      if (viewport) {
        var viewportContent = viewport.getAttribute("content");
        viewport.setAttribute(
          "content",
          viewportContent + ", user-scalable=no"
        );
      }
    }
  },
};
</script>
