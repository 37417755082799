<template>
  <div class="wrapper dashboard-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="pc-only main">
          <!-- <h1></h1> -->
          <div class="latest-news" v-if="newsFlag === true">
            <router-link to="/newslist" class="link-over">
              {{ $t("New notification") }}
            </router-link>
          </div>
          <div class="balance">
            <h2>{{ $t("CURRENT STAKING") }}</h2>
            <div class="total-amount">
              <span>{{ plateInfo.fp }}</span> AP
            </div>
            <div class="total-circle">
              <span>AP {{ $t("REWARD") }}</span> {{ plateInfo.fp2 }}AP
            </div>
            <div class="remail-box">
              {{ $t("REMAINING DAYS UNTIL STAKING IS LIFTED")
              }}<span>{{ plateInfo.days }}</span> {{ $t("Days") }}
            </div>
            <ul class="list">
              <li>
                <h3>RP</h3>
                <div class="amount">
                  <span
                    ><b>{{ plateInfo.rp }}</b></span
                  >
                </div>
              </li>
              <li>
                <h3>PP</h3>
                <div class="amount">
                  <span
                    ><b>{{ plateInfo.sp }}</b></span
                  >
                </div>
              </li>
            </ul>
          </div>
          <div class="package">
            <div class="box-wrap">
              <div class="btn">
                <a href="#" v-on:click="show2" class="link-over">{{
                  $t("Explanation of each point")
                }}</a>
              </div>
              <div
                v-if="!userInfo.isFpWithdrawal && Number(plateInfo.days) != 0"
                class="btn"
              >
                <router-link to="./deposit" class="link-over">{{
                  $t("DA-CHARGE")
                }}</router-link>
              </div>
              <div
                v-if="!userInfo.isFpWithdrawal && Number(plateInfo.days) != 0"
                class="btn"
              >
                <router-link to="./plan" class="link-over">{{
                  $t("DA-BUYPLAN")
                }}</router-link>
              </div>
              <div
                v-if="
                  !userInfo.isFpWithdrawal &&
                  Number(plateInfo.days) === 0 &&
                  Number(plateInfo.fp) > 0
                "
                class="btn white"
              >
                <router-link to="./restaking" class="link-over">{{
                  $t("RESAKING/WITHDRAWAL")
                }}</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <div class="sp-only">
            <!-- <h1></h1> -->
            <div class="latest-news display1Row" v-if="newsFlag === true">
              <router-link to="/newslist" class="link-over">
                {{ $t("New notification") }}
              </router-link>
            </div>
            <div class="balance">
              <h2>{{ $t("CURRENT STAKING") }}</h2>
              <div class="total-amount">
                <span>{{ plateInfo.fp }}</span> AP
              </div>
              <div class="total-circle">
                <span>AP {{ $t("REWARD") }}</span> {{ plateInfo.fp2 }}AP
              </div>
              <div class="remail-box">
                {{ $t("REMAINING DAYS UNTIL STAKING IS LIFTED")
                }}<span>{{ plateInfo.days }}</span> {{ $t("Days") }}
              </div>
              <ul class="list">
                <li>
                  <h3>RP</h3>
                  <div class="amount">
                    <span>{{ plateInfo.rp }}</span>
                  </div>
                </li>
                <li>
                  <h3>PP</h3>
                  <div class="amount">
                    <span>{{ plateInfo.sp }}</span>
                  </div>
                </li>
              </ul>
              <div class="package">
                <div class="link">
                  <span class="img">
                    <img src="../assets/img/info-icon-img.svg" alt="" />
                  </span>
                  <span class="txt link-over" v-on:click="show2">{{
                    $t("CLICK HERE FOR AN EXPLANATION OF EACH POINT")
                  }}</span>
                </div>
                <div
                  v-if="!userInfo.isFpWithdrawal && Number(plateInfo.days) != 0"
                  class="btn"
                >
                  <router-link to="./deposit" class="link-over">{{
                    $t("DA-CHARGE")
                  }}</router-link>
                </div>
                <div
                  v-if="!userInfo.isFpWithdrawal && Number(plateInfo.days) != 0"
                  class="btn"
                >
                  <router-link to="./plan" class="link-over">{{
                    $t("DA-BUYPLAN")
                  }}</router-link>
                </div>
                <div
                  v-if="
                    !userInfo.isFpWithdrawal &&
                    Number(plateInfo.days) === 0 &&
                    Number(plateInfo.fp) > 0
                  "
                  class="btn white"
                >
                  <router-link to="./restaking" class="link-over">{{
                    $t("RESAKING/WITHDRAWAL")
                  }}</router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="table">
            <h2>
              {{ $t("PLAN PURCHASE HISTORY") }}
              <router-link to="./history" class="link-over">{{
                $t("DETAILS")
              }}</router-link>
            </h2>
            <div class="tab-area">
              <div
                class="tab link-over"
                v-on:click="isSelect('Business')"
                v-bind:class="{ active: isActive === 'Business' }"
              >
                BUSINESS
              </div>
              <!-- <div
                class="tab link-over"
                v-on:click="isSelect('Investor')"
                v-bind:class="{ active: isActive === 'Investor' }"
              >
                INVESTOR
              </div> -->
            </div>
            <div
              class="table-wrap"
              v-show="isActive === 'Business'"
              v-bind:class="{ active: isActive === 'Business' }"
            >
              <table>
                <tr>
                  <th>{{ $t("DATE") }}</th>
                  <th>{{ $t("CURRENCY") }}</th>
                  <th>{{ $t("PLAN") }}</th>
                  <th>{{ $t("POINT AMOUNT") }}</th>
                </tr>
                <template v-if="activeList.length > 0">
                  <tr v-for="(item, index) in activeList" :key="index">
                    <td>{{ item.createdTime }}</td>
                    <td>{{ item.currency }}</td>
                    <td>{{ item.bonusSettingText }}</td>
                    <td>{{ item.pointsText }}&nbsp;</td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="4">{{ $t("NO DATA") }}</td>
                </tr>
              </table>
            </div>
            <div
              class="table-wrap"
              v-show="isActive === 'Investor'"
              v-bind:class="{ active: isActive === 'Investor' }"
            >
              <table>
                <tr>
                  <th>{{ $t("DATE") }}</th>
                  <th>{{ $t("CURRENCY") }}</th>
                  <th>{{ $t("PLAN") }}</th>
                  <th>{{ $t("POINT AMOUNT") }}</th>
                </tr>
                <template v-if="activeList.length > 0">
                  <tr v-for="(item, index) in activeList" :key="index">
                    <td>{{ item.createdTime }}</td>
                    <td>{{ item.currency }}</td>
                    <td>{{ item.bonusSettingText }}</td>
                    <td>{{ item.pointsText }}&nbsp;</td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="4">{{ $t("NO DATA") }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="table">
            <h2>
              {{ $t("STAKING REWARD HISTORY") }}
              <router-link to="./history" class="link-over">{{
                $t("DETAILS")
              }}</router-link>
            </h2>
            <table>
              <tr>
                <th>{{ $t("DATE") }}</th>
                <th>{{ $t("CURRENCY") }}</th>
                <th>{{ $t("REWARD") }}</th>
              </tr>
              <template v-if="fpList.length > 0">
                <tr v-for="(item, index) in fpList" :key="index">
                  <td>{{ item.createdTime }}</td>
                  <td>{{ item.assetName }}</td>
                  <td>{{ item.pointsText }}&nbsp;</td>
                </tr>
              </template>
              <tr v-else>
                <td colspan="4">{{ $t("NO DATA") }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <van-popup v-model:show="showRef" style="width: 90%">
      <div class="modal-box notice-box">
        <div class="text-area">
          <h2>{{ $t("Explanation of each point") }}</h2>
          <p>
            <span>USD</span>
            {{
              $t(
                "These are points that can be used to purchase plans or withdraw funds, and are awarded for deposits made with BTC, ETH and USDT. Bonuses are also awarded in USD."
              )
            }}
          </p>
          <p>
            <span>AP</span>
            {{ $t("Number of staking.") }}
          </p>
          <p>
            <span>RP</span>
            {{
              $t(
                "Points at which plans capital can be increased or transferred to users."
              )
            }}
          </p>
          <p>
            <span>PP</span>
            {{
              $t(
                "Points that exceed the binary bonus limit. Also 20% of the binary bonus will be granted as capital increase points until the capital reaches $50,000."
              )
            }}
          </p>
        </div>
        <div class="btn">
          <button v-on:click="hide2" type="button" class="link-over">
            {{ $t("Close") }}
          </button>
        </div>
      </div>
    </van-popup>

    <Footer :title="$t('My Wallet')" />
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
import Asset from "@/js/api/asset.js";
import Notice from "@/js/api/notice.js";

export default {
  name: "Dashboard",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      changeColor: false,
      showRef: false,
      newsFlag: false,
      amount: "",
      isActive: "Business",
      activeList: [],
      fpList: [],
      userInfo: {},
    };
  },
  computed: {
    addClass() {
      if (this.amount !== "") {
        return "done";
      } else {
        return "";
      }
    },
    plateInfo() {
      return this.$store.getters.getPlateInfo;
    },
  },
  mounted: function () {
    this.init();
    this.isHaveNewNotice();
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.getHistories();
    this.getFPs();
  },
  methods: {
    isSelect: function (num) {
      this.isActive = num;
      this.getHistories();
    },
    show2: function () {
      this.showRef = true;
    },
    hide2: function () {
      this.showRef = false;
    },
    init() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", vh + "px");

      var ua = navigator.userAgent.toLowerCase();
      var isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
      if (isiOS) {
        var viewport = document.querySelector('meta[name="viewport"]');
        if (viewport) {
          var viewportContent = viewport.getAttribute("content");
          viewport.setAttribute(
            "content",
            viewportContent + ", user-scalable=no"
          );
        }
      }
    },
    getHistories() {
      let data = {
        index: 0,
        take: 5,
        entryPlayType: this.isActive,
        historyType: "PlateActive",
      };
      Asset.getHistories(data).then((res) => {
        if (res) {
          this.activeList = res.data.detail;
        }
      });
    },
    getFPs() {
      let data = {
        index: 0,
        take: 5,
        assetType: "STANDARDTOKEN",
        Range: "Reward",
      };
      Asset.getHistories(data).then((res) => {
        if (res) {
          this.fpList = res.data.detail;
        }
      });
    },
    isHaveNewNotice() {
      Notice.isHaveNewNoticeAsync().then((res) => {
        if (res.data) {
          this.newsFlag = true;
        } else {
          this.newsFlag = false;
        }
      });
    },
  },
};
</script>
