import { createI18n } from "vue-i18n";
import { Locale } from "vant";
import enUS from "./en-US.json";
import jaJP from "./ja-JP.json";
import znCN from "./zh-CN.json";
import Definitions from "../definitions";
import enLocale from "vant/lib/locale/lang/en-US";
import zhLocale from "vant/lib/locale/lang/zh-CN";
import jaLocale from "vant/lib/locale/lang/ja-JP";

const messages = {
  "en-us": {
    ...enUS,
    ...enLocale,
  },
  "zh-cn": {
    ...znCN,
    ...zhLocale,
  },
  "ja-jp": {
    ...jaJP,
    ...jaLocale,
  },
};

const defaultLanguage = "ja-jp";
const defaultLocale = jaLocale;
const i18n = createI18n({
  locale:
    localStorage.getItem(Definitions.StorageKeys.language) || defaultLanguage,
  messages: messages,
  silentFallbackWarn: true,
  silentTranslationWarn: true,
});

setLanguage(localStorage.getItem(Definitions.StorageKeys.language));

function setLanguage(language) {
  let locale;
  switch (language?.toLowerCase()) {
    case "en-us":
      locale = enLocale;
      break;
    case "zh-cn":
      locale = zhLocale;
      break;
    case "ja-jp":
      locale = jaLocale;
      break;
    default:
      language = defaultLanguage;
      locale = defaultLocale;
      break;
  }
  localStorage.setItem(Definitions.StorageKeys.language, language);
  i18n.global.locale = language;
  vantLocales(language, locale);
}

// 更新vant组件库本身的语言变化，支持国际化
function vantLocales(language, locale) {
  Locale.use(language, locale);
}

export { i18n, setLanguage };
