<template>
  <div class="wrapper withdraw-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <div class="balance re">
            <h2>{{ $t("Possible withdrawal amount") }}</h2>
            <div class="total-amount">
              <span>{{ totalAp }}</span> AP
            </div>
            <ul class="list">
              <li>
                <h3>AP</h3>
                <div class="amount">
                  <span>{{ plateInfo.fp }}</span>
                </div>
              </li>
              <li>
                <h3>{{ $t("AP Reward") }}</h3>
                <div class="amount">
                  <span>{{ plateInfo.fp2 }}</span>
                </div>
              </li>
            </ul>
          </div>
          <div class="form">
            <form>
              <div class="input-area">
                <label class="label" for="address">
                  <input
                    type="text"
                    name=""
                    id="address"
                    placeholder=" "
                    v-model="withdrawForm.address"
                  />
                  <span class="group-label">{{
                    $t("Enter the wallet address of azero.")
                  }}</span>
                </label>
                <span class="circle" v-bind:class="{ done: hasAddress }">
                  <span class="bar"></span>
                </span>
              </div>
              <div class="txt">
                <p>{{ $t("※ the payment fee is 10%.") }}</p>
                <p class="red">
                  {{
                    $t(
                      "※ You will not be able to log in after withdrawing coins."
                    )
                  }}
                </p>
              </div>
              <div class="all-amount">
                <p>{{ $t("Amount to be withdrawn") }}</p>
                <div class="number">
                  <span>{{
                    (Number(totalAp) - Number(totalAp) * 0.1).toFixed(3)
                  }}</span>
                  AP
                </div>
                <p class="notice">
                  （{{ $t("Fees:") + (Number(totalAp) * 0.1).toFixed(3) }}AP）
                </p>
              </div>
              <div class="btn">
                <button
                  type="button"
                  class="link-over"
                  id="send_btn"
                  v-on:click="hintModalShow"
                  v-bind:disabled="!hasAddress"
                >
                  {{ $t("Withdraw application") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <van-popup v-model:show="show.hint" style="width: 90%">
      <div class="modal-box">
        <div class="modal-ttl">
          <p>
            {{ totalAp }}
            {{ $t("Apply for AP") }}<br />{{ $t("would you like it?") }}
          </p>
        </div>
        <div class="btn">
          <van-button
            type="button"
            class="link-over"
            :loading="loading.withdraw"
            @click="done"
            style="height: 54px"
          >
            {{ $t("Complete") }}
          </van-button>
        </div>
      </div>
    </van-popup>

    <van-popup
      v-model:show="show.twoFa"
      style="width: 90%"
      @close="withdrawForm.authenticationCode = ''"
    >
      <div class="modal-box">
        <form>
          <div class="modal-ttl">
            <p>
              {{ $t("Two-factor authentication") }}
            </p>
            <div class="input-box">
              <van-password-input
                :value="withdrawForm.authenticationCode"
                :mask="false"
                :focused="show.keyboard"
                @focus="show.keyboard = true"
              />
            </div>
          </div>
          <div class="btn">
            <van-button
              v-on:click="withdraw"
              type="button"
              class="link-over"
              :loading="loading.withdraw"
              style="height: 54px"
            >
              {{ $t("Authenticate") }}
            </van-button>
          </div>
        </form>
      </div>
    </van-popup>
    <van-number-keyboard
      v-model="withdrawForm.authenticationCode"
      :show="show.keyboard"
      @blur="show.keyboard = false"
    />
    <Footer title="WITHDRAWAL" />
  </div>
</template>
<style scoped></style>
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
import Withdrawal from "@/js/api/withdrawal";
import { i18n } from "../js/i18n/i18n";
import Common from "../js/common";
import Definitions from "../js/definitions";
import LoginUser from "@/js/loginUser.js";
import Plate from "../js/api/plate";
export default {
  name: "Withdraw",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      amountAp: 100,
      withdrawForm: {
        address: "",
        authenticationCode: "",
      },
      show: {
        twoFa: false,
        hint: false,
        keyboard: false,
      },
      loading: {
        withdraw: false,
      },
      userInfo: {},
    };
  },
  created() {
    this.userInfo = JSON.parse(
      sessionStorage.getItem(Definitions.StorageKeys.userInfo)
    );
  },
  computed: {
    hasAddress() {
      return this.withdrawForm.address;
    },
    plateInfo() {
      return this.$store.getters.getPlateInfo;
    },
    totalAp() {
      return Number(this.plateInfo.fp) + Number(this.plateInfo.fp2);
    },
  },
  methods: {
    hintModalShow: function () {
      this.show.hint = true;
    },
    hintModalHide: function () {
      this.show.hint = false;
    },
    done: function () {
      if (this.userInfo.twoFactorEnabled) {
        this.show.twoFa = true;
      } else {
        this.withdraw();
      }
    },
    withdraw() {
      if (!this.withdrawForm.address) {
        Common.warning(i18n.global.t("Please enter address."));
      } else if (
        this.userInfo.twoFactorEnabled &&
        (!this.withdrawForm.authenticationCode ||
          this.withdrawForm.authenticationCode.length < 6)
      ) {
        Common.warning(i18n.global.t("Please enter two-factor."));
      } else {
        this.loading.withdraw = true;
        const data = {
          withdrawalAddress: this.withdrawForm.address,
          amount: this.totalAp,
          authenticationCode: this.withdrawForm.authenticationCode,
          exchangeType: "StandardToken",
        };
        Withdrawal.addWithdrawalAsync(data)
          .then(() => {
            Common.success(
              i18n.global.t("The withdrawal application has been completed.")
            );
            this.withdrawForm.address = "";
            this.show.hint = false;
            this.show.twoFa = false;
            this.getPlateInfoAsync();
          })
          .finally(() => (this.loading.withdraw = false));
      }
    },
    getPlateInfoAsync() {
      if (
        !Common.isEmpty(
          localStorage.getItem(Definitions.StorageKeys.accessToken)
        )
      ) {
        this.userTitle = LoginUser.getUserTitle();
        Plate.getPlateInfo().then((res) => {
          if (res) {
            this.$store.commit("setPlateInfo", res.data);
            this.ap = res.data.ap;
            Common.loggedOut();
          }
        });
      }
    },
  },
  mounted: function () {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", vh + "px");

    var ua = navigator.userAgent.toLowerCase();
    var isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
    if (isiOS) {
      var viewport = document.querySelector('meta[name="viewport"]');
      if (viewport) {
        var viewportContent = viewport.getAttribute("content");
        viewport.setAttribute(
          "content",
          viewportContent + ", user-scalable=no"
        );
      }
    }
  },
};
</script>
<style lang="less" scoped>
:deep(.van-password-input) {
  width: 100%;
  margin: 0px;
}
:deep(.van-password-input__security li) {
  margin: 0px 4px;
  background: none;
  border: 1px solid #fff;
  color: #fff;
}
:deep(.van-password-input__security:after) {
  border: 0px;
}
:deep(.van-number-keyboard) {
  z-index: 10000;
}
:deep(.van-password-input__cursor) {
  background: #fff;
}
</style>
