import { createStore } from "vuex";

export default createStore({
  state: {
    screenWidth: document.body.clientWidth,
    plateInfo: {
      ap: 0,
      fp: 0,
      rp: 0,
      sp: 0,
      pp: 0,
      apPriceFrom: 0,
      apPriceTo: 0,
      entryPlayType: "",
      fp2: 0,
      days: 0,
    },
  },
  mutations: {
    setScreenWidth: (state, screenWidth) => {
      state.screenWidth = screenWidth;
    },
    setPlateInfo: (state, plateInfo) => {
      state.plateInfo.ap = plateInfo.ap;
      state.plateInfo.fp = plateInfo.fp;
      state.plateInfo.rp = plateInfo.rp;
      state.plateInfo.sp = plateInfo.sp;
      state.plateInfo.pp = plateInfo.pp;
      state.plateInfo.fp2 = plateInfo.fP2;
      state.plateInfo.days = plateInfo.days;
      state.plateInfo.apPriceFrom = plateInfo.apPriceFrom;
      state.plateInfo.apPriceTo = plateInfo.apPriceTo;
      state.plateInfo.entryPlayType = plateInfo.entryPlayType;
    },
  },
  getters: {
    getPlateInfo: (state) => state.plateInfo,
  },
  actions: {},
  modules: {},
});
