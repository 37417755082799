<template>
  <div class="wrapper maintenance-page">
    <!-- Header -->
    <Header />
    <div class="contents">
      <div class="layout">
        <div class="right">
          <div class="box">
            <h1>{{ $t("Maintenance") }}</h1>
            <p>
              {{
                $t("{item} is currently under maintenance", { item: $t("PDF") })
              }}
            </p>
            <p>
              {{
                $t("Please wait for a while until the maintenance is completed")
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->
    <Footer title='$t("Maintenance")' />
  </div>
</template>

<style lang="less" scoped>
.maintenance-page {
  :deep(.grade) {
    display: none !important;
  }
  :deep(.detail) {
    display: none !important;
  }

  .header {
    :deep(.grade) {
      display: none !important;
    }
    :deep(.detail) {
      display: none !important;
    }
    :deep(.menu) {
      display: none !important;
    }
  }
}

.maintenance-page {
  :deep(.footer) {
    padding-bottom: 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 9;
    .footer-fixed-menu {
      display: none;
    }
    .page-nav {
      display: none;
    }
    .language {
      .logo {
        img {
          display: none;
        }
      }
      .select-box {
        @media screen and (min-width: 767px) {
          margin-top: 0;
        }
      }
    }
  }
}
</style>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Maintenance",
  components: {
    Header,
    Footer,
  },
  data: () => {
    return {};
  },

  methods: {},

  mounted: function () {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", vh + "px");

    var ua = navigator.userAgent.toLowerCase();
    var isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
    if (isiOS) {
      var viewport = document.querySelector('meta[name="viewport"]');
      if (viewport) {
        var viewportContent = viewport.getAttribute("content");
        viewport.setAttribute(
          "content",
          viewportContent + ", user-scalable=no"
        );
      }
    }
  },
};
</script>
