<template>
  <div class="wrapper rate-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <h1>{{ $t("Regarding AP purchase price") }}</h1>
          <div class="rate-box">
            <p>
              {{
                $t(
                  "If the exchange‘s selling volume is low, the actual purchase price may be higher than the current rate."
                )
              }}<br />
              {{
                $t(
                  "For example, even at the current rate of 1 AP = 1.3750USD, the actual purchase price may vary by an average of 8-10% or more, depending on the number of APs required to purchase. In the case of the sell board shown below, the price would go up to 1.3898USD when purchasing approximately 3,000Azero."
                )
              }}
            </p>
            <div class="img">
              <img src="../assets/img/rate-img1.png" alt="" />
            </div>
            <p>
              {{
                $t(
                  "For example, if the number of Azero to be purchased is 30,000, you will be required to purchase at the *1 portion of the sell board that is not displayed (even higher rate)."
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "In addition, especially when purchasing during an uptrend, the minimum purchase price is gradually rounded up, so it is no longer possible to purchase from 1.3750 (red frame)."
                )
              }}
            </p>
            <h2>{{ $t("Specific purchase examples") }}</h2>
            <p>
              {{ $t("The premise is that AZERO is in a long-term uptrend.") }}
            </p>
            <div class="img">
              <img src="../assets/img/rate-img2.png" alt="" />
            </div>
            <p>{{ $t("To purchase 150,000 USDT worth of AZERO.") }}</p>
            <p>
              {{ $t("[Buy at limit price]") }}<br />
              {{
                $t(
                  "If you are able to buy all at the current rate of 1.3749, you will get 109099AZERO."
                )
              }}
            </p>
            <div class="img">
              <img src="../assets/img/rate-img3.png" alt="" />
            </div>
            <p>
              {{
                $t(
                  "However, only about 200 Azero can actually be purchased at 1.3749."
                )
              }}<br />
              {{
                $t("Therefore, it is necessary to make the following purchase.")
              }}
            </p>
            <p>
              {{ $t("［Buy on the market]") }}<br />
              {{
                $t(
                  "If you buy the same number of 109099AZERO as the limit price above, the following purchase rate is assumed (assuming that the sell board is re-generated)."
                )
              }}
            </p>
            <div class="scroll">
              <ul class="list">
                <li>
                  <p class="center">{{ $t("First time") }}</p>
                  <p>1.3749～1.3898 3000枚</p>
                  <p>1.3899～1.4038 3000枚</p>
                  <p>1.4039～1.4179 3000枚</p>
                  <p>1.4180～1.4322 3000枚</p>
                  <p>1.4323～1.4466 3000枚</p>
                  <p>1.4467～1.4612 3000枚</p>
                  <p>1.4613～1.4759 3000枚</p>
                  <p>1.4760～1.4907 3000枚</p>
                  <p>1.4908～1.5057 3000枚</p>
                  <p>1.5058～1.5208 3000枚</p>
                </li>
                <li>
                  <p class="center">{{ $t("Second time") }}</p>
                  <p>1.4180～1.4322 3000枚</p>
                  <p>1.4323～1.4466 3000枚</p>
                  <p>1.4467～1.4612 3000枚</p>
                  <p>1.4613～1.4759 3000枚</p>
                  <p>1.4760～1.4907 3000枚</p>
                  <p>1.4908～1.5057 3000枚</p>
                  <p>1.5058～1.5208 3000枚</p>
                  <p>1.5209～1.5361 3000枚</p>
                  <p>1.5362～1.5515 3000枚</p>
                  <p>1.5516～1.5671 3000枚</p>
                </li>
                <li>
                  <p class="center">{{ $t("Third time") }}</p>
                  <p>1.4613～1.4759 3000枚</p>
                  <p>1.4760～1.4907 3000枚</p>
                  <p>1.4908～1.5057 3000枚</p>
                  <p>1.5058～1.5208 3000枚</p>
                  <p>1.5209～1.5361 3000枚</p>
                  <p>1.5362～1.5515 3000枚</p>
                  <p>1.5516～1.5671 3000枚</p>
                  <p>1.5672～1.5829 3000枚</p>
                  <p>1.5830～1.5988 3000枚</p>
                  <p>1.5989～1.6149 3000枚</p>
                </li>
                <li>
                  <p class="center">{{ $t("Fourth time") }}</p>
                  <p>1.5058～1.5208 3000枚</p>
                  <p>1.5209～1.5361 3000枚</p>
                  <p>1.5362～1.5515 3000枚</p>
                  <p>1.5516～1.5671 3000枚</p>
                  <p>1.5672～1.5829 3000枚</p>
                  <p>1.5830～1.5988 3000枚</p>
                  <p>1.5989～1.6068 1099枚</p>
                </li>
              </ul>
            </div>
            <p>
              {{
                $t(
                  "As stated above, there can be a deviation of approximately 17% from the lowest rate to the highest rate in a single day. When averaged out, a deviation of approximately 8% to 10% from the lowest rate is expected."
                )
              }}
            </p>
            <p>
              {{ $t("※The above example is for reference only.") }}<br />
              {{
                $t(
                  "In actual cases, the rate and number of lots may vary depending on the thickness of the selling board, so the gap between the lowest and highest rates may widen."
                )
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <Footer title="Rate" />
  </div>
</template>
<style scoped></style>
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Rate",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      title: "Rate",
    };
  },
  methods: {},
  computed: {},
  mounted: function () {},
};
</script>
