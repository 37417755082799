<template>
  <div class="wrapper referral-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <h1>{{ $t("REFERRAL") }}</h1>
          <div class="tab-area">
            <div
              class="tab link-over"
              v-on:click="isSelect('1')"
              v-bind:class="{ active: isActive === '1' }"
            >
              {{ $t("LEFT REFERRAL") }}
            </div>
            <div
              class="tab link-over"
              v-on:click="isSelect('2')"
              v-bind:class="{ active: isActive === '2' }"
            >
              {{ $t("RIGHT REFERRAL") }}
            </div>
          </div>
          <div class="tab-contents" v-show="isActive === '1'">
            <div class="copy-area">
              <h2>{{ $t("REFERRAL CODE") }}</h2>
              <div class="input-area">
                <input type="text" v-model="code" readonly />
                <span
                  class="copy-btn"
                  v-clipboard:copy="code"
                  v-clipboard:success="codeCopy"
                  v-clipboard:error="codeError"
                  >{{ $t("COPY") }}</span
                >
              </div>
            </div>
            <div class="copy-area">
              <h2>{{ $t("REFERRAL URL") }}</h2>
              <div class="input-area">
                <input type="text" v-model="url" readonly />
                <span
                  class="copy-btn"
                  v-clipboard:copy="url"
                  v-clipboard:success="urlCopy"
                  v-clipboard:error="urlError"
                  >{{ $t("COPY") }}</span
                >
              </div>
            </div>
          </div>
          <div class="tab-contents" v-show="isActive === '2'">
            <div class="copy-area">
              <h2>{{ $t("REFERRAL CODE") }}</h2>
              <div class="input-area">
                <input type="text" v-model="code" readonly />
                <span
                  class="copy-btn"
                  v-clipboard:copy="code"
                  v-clipboard:success="urlCopy"
                  v-clipboard:error="urlError"
                  >{{ $t("COPY") }}</span
                >
              </div>
            </div>
            <div class="copy-area">
              <h2>{{ $t("REFERRAL URL") }}</h2>
              <div class="input-area">
                <input type="text" v-model="url" readonly />
                <span
                  class="copy-btn"
                  v-clipboard:copy="url"
                  v-clipboard:success="urlCopy"
                  v-clipboard:error="urlError"
                  >{{ $t("COPY") }}</span
                >
              </div>
            </div>
          </div>
          <div class="table">
            <h2>{{ $t("REFERRAL HISTORY") }}</h2>
            <table>
              <tr>
                <th>{{ $t("DATE") }}</th>
                <th>{{ $t("USER NAME") }}</th>
                <!-- <th>ポジション</th>
                <th>ステータス</th> -->
              </tr>
              <template v-if="tableList.length > 0">
                <tr v-for="(item, index) in tableList" :key="index">
                  <td>{{ item.createdTime }}</td>
                  <td>{{ item.userName }}</td>
                </tr>
              </template>
              <tr v-else>
                <td colspan="4">{{ $t("NO DATA") }}</td>
              </tr>
            </table>
            <van-pagination
              v-if="total > 4"
              v-model="currentPage"
              :total-items="total"
              :show-page-size="3"
              :items-per-page="4"
              @change="onPage"
              force-ellipses
            >
              <template #prev-text>
                {{ $t("PREVIOUS") }}
              </template>
              <template #next-text>
                {{ $t("NEXT") }}
              </template>
            </van-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <Footer :title="$t('REFERRAL')" />
  </div>
</template>
<style scoped></style>
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
import LoginUser from "@/js/loginUser";
import Common from "@/js/common";
import { Notify } from "vant";
import Account from "@/js/api/account";

export default {
  name: "Referral",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      title: "Referral",
      code: "",
      url: "",
      isActive: "1",
      currentPage: 1,
      total: 0,
      tableList: [],
    };
  },
  created() {
    this.listDownLines();
  },
  computed: {},
  mounted: function () {
    if (LoginUser.getUserInfo()) {
      this.code = LoginUser.getUserName();
      this.url =
        Common.hostUrl +
        "/#/Signup?SeatNo=" +
        this.isActive +
        "&Introducer=" +
        LoginUser.getUserName();
    }
  },
  methods: {
    isSelect: function (num) {
      this.isActive = num;
      if (LoginUser.getUserInfo()) {
        this.code = LoginUser.getUserName();
        this.url =
          Common.hostUrl +
          "/#/Signup?SeatNo=" +
          this.isActive +
          "&Introducer=" +
          LoginUser.getUserName();
      }
    },
    urlCopy: function () {
      Notify({ type: "success", message: this.$t("Copy successfully") });
    },
    urlError: function () {
      Notify({ type: "danger", message: this.$t("Copy failed") });
    },
    onPage(val) {
      this.currentPage = val;
      this.listDownLines();
    },
    listDownLines() {
      Account.listDownLines(this.currentPage - 1, 4).then((res) => {
        if (res) {
          this.tableList = res.data.detail;
          this.total = res.data.total;
        }
      });
    },
  },
};
</script>
