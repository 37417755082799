<template>
  <div class="wrapper withdraw-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <h1>{{ $t("TRANSFER & EXCHANGE") }}</h1>
          <div class="tab-area">
            <div
              class="tab link-over"
              v-on:click="isSelect('1')"
              v-bind:class="{ active: isActive === '1' }"
            >
              {{ $t("TRANSFER") }}
            </div>
            <div
              class="tab link-over"
              v-on:click="isSelect('2')"
              v-bind:class="{ active: isActive === '2' }"
            >
              {{ $t("EXCHANGE") }}
            </div>
          </div>
          <div class="content-area" v-show="isActive === '1'">
            <div class="balance">
              <h2>{{ $t("TRANSFERABLE AMOUNT") }}</h2>
              <div class="total-amount">
                <span>{{ rp }}</span> RP
              </div>
            </div>
            <div class="form">
              <form>
                <div class="input-area">
                  <label class="label" for="user">
                    <input
                      type="text"
                      name=""
                      id="user"
                      placeholder=" "
                      v-model="userName"
                    />
                    <span class="group-label">{{
                      $t("Please enter your user name.")
                    }}</span>
                  </label>
                  <span class="circle" v-bind:class="{ done: hasUserName }">
                    <span class="bar"></span>
                  </span>
                </div>
                <!-- <p class="vali-message">ユーザーが存在しません。</p> -->
                <div class="input-area">
                  <label class="label" for="amount">
                    <input
                      type="number"
                      name=""
                      id="amount"
                      placeholder=" "
                      v-model="amount"
                    />
                    <span class="group-label">{{
                      $t("Please enter the amount to be transferred.")
                    }}</span>
                  </label>
                  <span class="circle" v-bind:class="{ done: hasAmount }">
                    <span class="bar"></span>
                  </span>
                </div>
                <!-- <p class="vali-message">残高不足</p> -->
                <div class="btn">
                  <button
                    type="button"
                    class="link-over"
                    id="send_btn"
                    v-on:click="show"
                    v-bind:disabled="!hasChecked"
                  >
                    {{ $t("TRANSFER") }}
                  </button>
                </div>
              </form>
            </div>
            <div class="table">
              <h2>{{ $t("TRANSFER HISTORY") }}</h2>
              <table>
                <tr>
                  <th>{{ $t("DATE") }}</th>
                  <th>{{ $t("USER NAME") }}</th>
                  <th>{{ $t("TRANSFER VOLUME") }}</th>
                </tr>
                <template v-if="toUserList.length > 0">
                  <tr v-for="(item, index) in toUserList" :key="index">
                    <td>{{ item.createdTime }}</td>
                    <td>{{ item.relationUserName }}</td>
                    <td>{{ item.pointsText }} RP</td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="4">{{ $t("NO DATA") }}</td>
                </tr>
              </table>
              <van-pagination
                v-if="toUserCount > 5"
                v-model="toUserCurrentPage"
                :total-items="toUserCount"
                :show-page-size="3"
                :items-per-page="5"
                @change="onPageToUser"
                force-ellipses
              >
                <template #prev-text>
                  {{ $t("PREVIOUS") }}
                </template>
                <template #next-text>
                  {{ $t("NEXT") }}
                </template>
              </van-pagination>
            </div>
          </div>
          <div class="content-area" v-show="isActive === '2'">
            <div class="balance">
              <h2>{{ $t("EXCHANGEABLE AMOUNT") }}</h2>
              <div class="total-amount">
                <span>{{ usd }}</span> USD
              </div>
            </div>
            <div class="form">
              <form>
                <div class="input-area">
                  <label class="label" for="amountusd">
                    <input
                      type="text"
                      name=""
                      id="amountusd"
                      placeholder=" "
                      v-model.number="amountUsd"
                    />
                    <span class="group-label">{{
                      $t("Please enter the amount to be exchanged.")
                    }}</span>
                  </label>
                  <span class="circle" v-bind:class="{ done: hasUsdAmount }">
                    <span class="bar"></span>
                  </span>
                </div>
                <div class="all-amount">
                  <p>{{ $t("AMOUNT TO BE EXCHANGED") }}</p>
                  <div class="number">
                    <span v-if="!amountUsd">- - - - - -</span>
                    <span else>{{ amountUsd }}</span>
                    RP
                  </div>
                </div>
                <div class="btn">
                  <button
                    type="button"
                    class="link-over"
                    v-on:click="show2"
                    v-bind:disabled="!amountUsd"
                  >
                    {{ $t("EXCHANGE") }}
                  </button>
                </div>
              </form>
            </div>
            <div class="table">
              <h2>{{ $t("EXCHANGE HISTORY") }}</h2>
              <table>
                <tr>
                  <th>{{ $t("DATE") }}</th>
                  <th>{{ $t("EXCHANGE VOLUME") }}</th>
                </tr>
                <template v-if="toPointList.length > 0">
                  <tr v-for="(item, index) in toPointList" :key="index">
                    <td>{{ item.createdTime }}</td>
                    <td>{{ item.pointsText }} RP</td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="4">{{ $t("NO DATA") }}</td>
                </tr>
              </table>
              <van-pagination
                v-if="toPointCount > 5"
                v-model="toPointCurrentPage"
                :total-items="toPointCount"
                :show-page-size="3"
                :items-per-page="5"
                @change="onPageToPoint"
                force-ellipses
              >
                <template #prev-text>
                  {{ $t("PREVIOUS") }}
                </template>
                <template #next-text>
                  {{ $t("NEXT") }}
                </template>
              </van-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->
    <van-popup v-model:show="sendModal" style="width: 90%">
      <div class="modal-box">
        <div class="modal-ttl">
          <p>
            {{ amount }} RPを{{ userName }}に<br class="sp-only" />{{
              $t("Are you sure you want to transfer it?")
            }}
          </p>
        </div>
        <div class="btn">
          <button
            v-on:click="hide"
            type="button"
            class="link-over"
            @click="pointToUser"
          >
            {{ $t("Complete the transfer") }}
          </button>
        </div>
      </div>
    </van-popup>
    <van-popup v-model:show="changeModal" style="width: 90%">
      <div class="modal-box">
        <div class="modal-ttl">
          <p>
            {{ amountUsd }} USDを{{ amountUsd }}RPに<br class="sp-only" />{{
              $t("EXCHANGE")
            }}
          </p>
        </div>
        <div class="btn">
          <button
            v-on:click="hide2"
            type="button"
            class="link-over"
            @click="pointToPoint"
          >
            {{ $t("Complete") }}
          </button>
        </div>
      </div>
    </van-popup>
    <Footer title="TRANSFER & EXCHANGE" />
  </div>
</template>
<style scoped></style>
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
import Asset from "@/js/api/asset.js";
import Plate from "@/js/api/plate.js";
import { Notify } from "vant";

export default {
  name: "Withdraw",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      title: "Transfer",
      userName: "",
      amount: "",
      isActive: "1",
      amountUsd: "",
      sendModal: false,
      changeModal: false,
      rp: 0,
      usd: 0,
      toUserCount: 0,
      toUserList: [],
      toUserCurrentPage: 1,
      toPointCount: 0,
      toPointCurrentPage: 1,
      toPointList: [],
    };
  },
  created() {
    this.getToUserList();
    this.getToPointList();
  },
  methods: {
    show: function () {
      this.sendModal = true;
    },
    hide: function () {
      this.sendModal = false;
    },
    show2: function () {
      this.changeModal = true;
    },
    hide2: function () {
      this.changeModal = false;
    },
    isSelect: function (num) {
      this.isActive = num;
    },
    pointToUser() {
      if (!Number(this.amount)) {
        this.amount = "";
      }
      let data = {
        AssetType: "RP",
        ToUser: this.userName,
        Point: Number(this.amount),
        //PayPassword: this.password,
      };
      Asset.pointToUser(data).then((res) => {
        if (res) {
          Notify({
            type: "success",
            message: "success",
          });
          this.userName = "";
          this.amount = "";
          //this.password = "";
          this.getToUserList();
          this.setPlateInfo();
        }
      });
    },
    pointToPoint() {
      let data = {
        FromAsset: "AP",
        FromPoint: Number(this.amountUsd),
        ToAsset: "RP",
        ToUser: this.userName,
        //PayPassword: this.password,
      };
      Asset.pointToPoint(data).then((res) => {
        if (res) {
          Notify({
            type: "success",
            message: "success",
          });
          this.amountUsd = "";
          //this.password = "";
          //this.getPlateInfo();
          //this.getHistories();
          this.setPlateInfo();
        }
      });
    },
    setPlateInfo() {
      Plate.getPlateInfo().then((res) => {
        if (res) {
          this.$store.commit("setPlateInfo", res.data);
          this.ap = res.data.ap;
        }
      });
    },
    getPlateInfo() {
      this.rp = this.$store.getters.getPlateInfo.rp;
      this.usd = this.$store.getters.getPlateInfo.ap;
    },
    onPageToUser(val) {
      this.toUserCurrentPage = val;
      this.getToUserList();
    },
    getToUserList() {
      let data = {
        index: this.toUserCurrentPage - 1,
        take: 5,
        assetType: "RP",
        historyType: "pointToUser",
      };
      Asset.getHistories(data).then((res) => {
        if (res) {
          this.toUserList = res.data.detail;
          this.toUserCount = res.data.total;
        }
      });
    },
    onPageToPoint(val) {
      this.toPointCurrentPage = val;
      this.getToPointList();
    },
    getToPointList() {
      let data = {
        index: this.toPointCurrentPage - 1,
        take: 5,
        assetType: "AP",
        historyType: "pointToPoint",
      };
      Asset.getHistories(data).then((res) => {
        if (res) {
          this.toPointList = res.data.detail;
          this.toPointCount = res.data.total;
        }
      });
    },
  },
  watch: {
    "$store.state.plateInfo.ap": function () {
      this.getPlateInfo();
    },
    "$store.state.plateInfo.rp": function () {
      this.getPlateInfo();
    },
  },
  computed: {
    hasUserName() {
      return this.userName;
    },
    hasAmount() {
      return this.amount;
    },
    hasChecked() {
      return this.userName && this.amount;
    },
    hasUsdAmount() {
      return this.amountUsd;
    },
  },
  mounted: function () {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", vh + "px");
    this.getPlateInfo();
    var ua = navigator.userAgent.toLowerCase();
    var isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
    if (isiOS) {
      var viewport = document.querySelector('meta[name="viewport"]');
      if (viewport) {
        var viewportContent = viewport.getAttribute("content");
        viewport.setAttribute(
          "content",
          viewportContent + ", user-scalable=no"
        );
      }
    }
  },
};
</script>
