<template>
  <div class="wrapper account-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <h1>{{ $t("ACCOUNT") }}</h1>
          <div class="tab-area">
            <!-- <div
              class="tab link-over"
              v-on:click="isSelect('1')"
              v-bind:class="{ active: isActive === '1' }"
            >
              登録情報
            </div> -->
            <!-- <div
              class="tab link-over"
              v-on:click="isSelect('2')"
              v-bind:class="{ active: isActive === '2' }"
            >
              契約情報
            </div> -->
          </div>
          <div class="content-area" v-show="isActive === '1'">
            <div class="form">
              <form action="" method="">
                <!-- USER NAME -->
                <div class="input-area">
                  <label class="label" for="account">
                    <input
                      type="text"
                      name=""
                      id="account"
                      v-model="userName"
                      readonly
                    />
                    <span class="group-label">{{ $t("USER NAME") }}</span>
                  </label>
                  <span class="circle done">
                    <span class="bar"></span>
                  </span>
                </div>
                <!-- EMAIL -->
                <div class="input-area password">
                  <label class="label" for="email">
                    <input
                      type="text"
                      name=""
                      id="email"
                      v-model="email"
                      readonly
                    />
                    <span class="group-label">{{
                      $t("E-MAIL ADDRESS CHANGE")
                    }}</span>
                  </label>
                  <span v-show="emailConfirmed" class="edit" @click="onEmail">{{
                    $t("Edit")
                  }}</span>
                  <span
                    v-show="!emailConfirmed"
                    class="edit"
                    @click="onEmail"
                    >{{ $t("Authenticate") }}</span
                  >
                  <span class="circle done">
                    <span class="bar"></span>
                  </span>
                </div>
                <!-- EMAIL -->
                <div class="input-area password">
                  <label class="label" for="password">
                    <input
                      type="text"
                      name=""
                      id="password"
                      value="********"
                      readonly
                    />
                    <span class="group-label">{{ $t("PASSWORD") }}</span>
                  </label>
                  <span class="edit" @click="onPassword">{{ $t("Edit") }}</span>
                  <span class="circle done">
                    <span class="bar"></span>
                  </span>
                </div>
                <!-- PHONE NUMBER -->
                <!-- <div class="input-area">
                  <label class="label" for="phone_number">
                    <input
                      type="text"
                      name=""
                      id="phone_number"
                      v-model="phoneNumber"
                      placeholder=""
                      readonly
                    />
                    <span class="group-label">{{ $t("PHONE NUMBER") }}</span>
                  </label>
                  <span class="circle done">
                    <span class="bar"></span>
                  </span>
                </div> -->
                <!-- GOOGLE AUTHENTICATOR -->
                <div class="input-area password">
                  <label class="label" for="google">
                    <input
                      type="text"
                      id="google"
                      placeholder=""
                      readonly
                      :value="twoFactor == true ? 'On' : 'Off'"
                    />
                    <span class="group-label">{{
                      $t("TWO-FACTOR AUTHENTICATION")
                    }}</span>
                  </label>
                  <router-link to="./authentication" class="edit link-over">{{
                    $t("Authenticate")
                  }}</router-link>
                  <span class="circle done">
                    <span class="bar"></span>
                  </span>
                </div>
                <!-- REFERRAL CODE -->
                <div class="input-area">
                  <label class="label" for="referal_code">
                    <input
                      type="text"
                      name=""
                      id="referal_code"
                      v-model="introducer"
                      readonly
                    />
                    <span class="group-label">{{ $t("REFERRAL CODE") }}</span>
                  </label>
                  <span class="circle done">
                    <span class="bar"></span>
                  </span>
                </div>
              </form>
            </div>
          </div>
          <div class="content-area" v-show="isActive === '2'">
            <div class="form">
              <form>
                <div class="img">
                  <img src="../assets/img/account-pdf-img.svg" alt="" />
                </div>
                <!-- USER NAME -->
                <div class="input-area">
                  <label class="label" for="name">
                    <input
                      type="text"
                      name=""
                      id="name"
                      v-model="firstName"
                      readonly
                    />
                    <span class="group-label">お名前</span>
                  </label>
                  <span class="circle done">
                    <span class="bar"></span>
                  </span>
                </div>
                <!-- PHONE NUMBER -->
                <div class="input-area">
                  <label class="label" for="phone_number">
                    <input
                      type="text"
                      name=""
                      id="phone_number"
                      v-model="phoneNumber"
                      placeholder=""
                      readonly
                    />
                    <span class="group-label">電話番号</span>
                  </label>
                  <span class="circle done">
                    <span class="bar"></span>
                  </span>
                </div>
              </form>
            </div>
          </div>
          <div class="terms-link">
            <router-link to="/terms" class="link-over">{{
              $t("TERMS OF USE")
            }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <Footer :title="$t('ACCOUNT')" />
    <van-popup
      v-model:show="showPassword"
      style="width: 90%; background-color: #fff; border-radius: 16px"
    >
      <div class="modal-box notice-box">
        <div class="text-area">
          <h2>{{ $t("Set a new password") }}</h2>
          <form>
            <div class="input-area password">
              <label class="label" for="modal_old">
                <input
                  type="password"
                  id="modal_old"
                  placeholder=" "
                  v-model="oldPassword"
                />
                <span class="group-label">{{ $t("CURRENT PASSWORD") }}</span>
              </label>
              <!-- <span class="circle" :class="addOld">
                <span class="bar"></span>
              </span> -->
            </div>
            <div class="input-area password">
              <label class="label" for="modal_new">
                <input
                  type="password"
                  id="modal_new"
                  placeholder=" "
                  v-model="newPassword"
                />
                <span class="group-label">{{ $t("NEW PASSWORD") }}</span>
              </label>
              <!-- <span class="circle" :class="addNew">
                <span class="bar"></span>
              </span> -->
            </div>
            <div class="input-area password">
              <label class="label" for="modal_confirm">
                <input
                  type="password"
                  id="modal_confirm"
                  placeholder=" "
                  v-model="confirmPassword"
                />
                <span class="group-label">{{ $t("CONFIRM PASSWORD") }}</span>
              </label>
              <!-- <span class="circle" :class="addConfirm">
                <span class="bar"></span>
              </span> -->
            </div>
          </form>
          <div class="btn">
            <button type="button" class="link-over" @click="hide">
              {{ $t("Complete the settings") }}
            </button>
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup
      v-model:show="showEmail"
      style="width: 90%; background-color: #fff; border-radius: 16px"
    >
      <div class="modal-box notice-box">
        <div class="text-area">
          <h2>{{ $t("Email authentication") }}</h2>
          <form>
            <div class="input-area" v-show="emailConfirmed">
              <label class="label" for="modal_email">
                <input
                  type="text"
                  placeholder=" "
                  id="modal_email"
                  v-model="oldEmail"
                />
                <span class="group-label">{{ $t("EMAIL") }}</span>
              </label>
            </div>
            <div class="input-area password">
              <label class="label" for="modal_pwd">
                <input
                  type="password"
                  placeholder=" "
                  id="modal_pwd"
                  v-model="password"
                />
                <span class="group-label">{{ $t("PASSWORD") }}</span>
              </label>
            </div>
            <div class="input-area">
              <label class="label" for="modal_emailCode">
                <input
                  type="text"
                  placeholder=" "
                  id="modal_emailCode"
                  v-model="emailCode"
                />
                <span class="group-label">{{
                  $t("Email Verification Code")
                }}</span>
              </label>
              <van-button
                native-type="button"
                size="mini"
                class="link-over edit"
                :loading="loading"
                @click="onEmailSend"
                >{{ $t("Send") }}</van-button
              >
            </div>
          </form>
        </div>
        <div class="btn">
          <button type="button" class="link-over" @click="hideEmail">
            {{ $t("Email authentication") }}
          </button>
        </div>
      </div>
    </van-popup>

    <van-popup
      v-model:show="emailModal"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="90%"
      height="auto"
    >
      <div class="modal-box notice-box">
        <div class="text-area">
          <form>
            <div
              class="txt"
              v-html="
                $t(
                  'URL for change has been sent to your registered e-mail address.'
                )
              "
            ></div>
          </form>
        </div>
        <div class="btn">
          <button v-on:click="hide2" type="button" class="link-over">
            {{ $t("Close") }}
          </button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<style scoped></style>
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
import LoginUser from "@/js/loginUser.js";
import Account from "@/js/api/account";
import Common from "@/js/common";
import { Notify } from "vant";

export default {
  name: "Account",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      title: "Account",
      isActive: "1",
      userName: LoginUser.getUserName(),
      email: LoginUser.getEmail(),
      phoneNumber: LoginUser.getPhoneNumber(),
      introducer: LoginUser.getIntroducer(),
      firstName: LoginUser.getFirstName(),
      twoFactor: LoginUser.isTwoFactorEnabled(),
      showPassword: false,
      showEmail: false,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      password: "",
      oldEmail: "",
      emailCode: "",
      emailConfirmed: LoginUser.getEmailConfirmed(),
      loading: false,
      emailModal: false,
    };
  },
  methods: {
    onPassword() {
      this.oldPassword = "";
      this.newPassword = "";
      this.confirmPassword = "";
      this.showPassword = true;
    },
    hide() {
      let data = {
        currentPassword: this.oldPassword,
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
        authenticationCode: "",
      };
      Account.changePassword(data).then((res) => {
        if (res) {
          Notify({ type: "success", message: "success" });
          this.showPassword = false;
          Common.loggedOut();
        }
      });
    },
    hide2() {
      this.emailModal = false;
    },
    isSelect: function (num) {
      this.isActive = num;
    },
    onEmail: function () {
      Account.sendChangeEmailUrlAsync();
      this.emailModal = true;
      // this.showEmail = true;
      // this.oldEmail = "";
      // this.password = "";
      // this.emailCode = "";
    },
    hideEmail() {
      if (!this.emailConfirmed) {
        if (this.emailCode) {
          let data = {
            verificationCode: this.emailCode,
          };
          Account.verifyEmailSelf(data).then((res) => {
            if (res) {
              Notify({ type: "success", message: "success" });
              this.showEmail = false;
              Account.getLoginInfoAsync().then(() => {
                this.username = LoginUser.getUserName();
                this.referralCode = LoginUser.getReferralCode();
                this.email = LoginUser.getEmail();
                this.emailConfirmed = LoginUser.getEmailConfirmed();
              });
            }
          });
        } else {
          Notify({
            type: "danger",
            message: this.$t("Verification Code is required."),
          });
        }
      } else {
        if (this.oldEmail) {
          if (this.emailCode) {
            let data = {
              email: this.oldEmail,
              verificationCode: this.emailCode,
            };
            Account.verifyEmail(data).then((res) => {
              if (res) {
                Notify({ type: "success", message: "success" });
                this.showEmail = false;
                Account.getLoginInfoAsync().then(() => {
                  this.username = LoginUser.getUserName();
                  this.referralCode = LoginUser.getReferralCode();
                  this.email = LoginUser.getEmail();
                  this.emailConfirmed = LoginUser.getEmailConfirmed();
                });
              }
            });
          } else {
            Notify({
              type: "danger",
              message: this.$t("Verification Code is required."),
            });
          }
        } else {
          Notify({ type: "danger", message: this.$t("Email is required.") });
        }
      }
    },
    onEmailSend() {
      if (!this.emailConfirmed) {
        if (this.password) {
          let data = {
            password: this.password,
          };
          this.loading = true;
          Account.sendEmailVerificationCodeSelf(data).then((res) => {
            this.loading = false;
            if (res) {
              Notify({
                type: "success",
                message: this.$t(
                  "Verification code has been sent to your email"
                ),
              });
            }
          });
        } else {
          Notify({
            type: "danger",
            message: this.$t("Password is required."),
          });
        }
      } else {
        if (this.oldEmail) {
          this.loading = true;
          Account.isEmailExists(this.oldEmail).then((res) => {
            this.loading = false;
            if (res) {
              if (res.data) {
                Notify({ type: "danger", message: this.$t("Already Exists.") });
              } else {
                if (this.password) {
                  let data = {
                    email: this.oldEmail,
                    password: this.password,
                  };
                  Account.sendEmaileVerificationCode(data).then((res) => {
                    if (res) {
                      Notify({
                        type: "success",
                        message: this.$t(
                          "Verification code has been sent to your email"
                        ),
                      });
                    }
                  });
                } else {
                  Notify({
                    type: "danger",
                    message: this.$t("Password is required."),
                  });
                }
              }
            }
          });
        } else {
          Notify({ type: "danger", message: this.$t("Email is required.") });
        }
      }
    },
  },
  computed: {
    addOld() {
      if (this.oldPassword !== "") {
        return "done";
      } else {
        return "";
      }
    },
    addNew() {
      if (this.newPassword !== "") {
        return "done";
      } else {
        return "";
      }
    },
    addConfirm() {
      if (this.confirmPassword !== "") {
        return "done";
      } else {
        return "";
      }
    },
  },
  mounted: function () {},
};
</script>
<style lang="less" scoped>
.edit {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  background-color: #2c3b4d;
  color: #fff;
  font-size: 10px;
  padding: 4px 12px;
  line-height: 1;
  top: 50%;
  right: 14%;
  transform: translateY(-50%) translateX(0%);
  border-radius: 10px;
}
:deep(.van-popup--center) {
  width: 90%;
  max-width: 400px;
  border-radius: 15px;
  .txt {
    color: #fff;
    font-weight: 600;
    text-align: center;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 1.75;
  }
}
</style>
