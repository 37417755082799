<template>
  <div class="wrapper terms-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <h1>{{ $t("Terms of service") }}</h1>
          <div class="pdf">
            <img src="../assets/pdf/terms-pdf-1.jpg" alt="" />
            <img src="../assets/pdf/terms-pdf-2.jpg" alt="" />
            <img src="../assets/pdf/terms-pdf-3.jpg" alt="" />
            <img src="../assets/pdf/terms-pdf-4.jpg" alt="" />
            <img src="../assets/pdf/terms-pdf-5.jpg" alt="" />
            <img src="../assets/pdf/terms-pdf-6.jpg" alt="" />
            <img src="../assets/pdf/terms-pdf-7.jpg" alt="" />
            <img src="../assets/pdf/terms-pdf-8.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->

    <Footer title="Terms" />
  </div>
</template>
<style scoped></style>
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Terms",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      title: "Terms",
    };
  },
  methods: {},
  computed: {},
  mounted: function () {},
};
</script>
