import Fetch from "@/js/api/fetch";

const Plate = {};

Plate.getPlateInfo = () => Fetch.get("/Plate/GetPlateInfo");

Plate.activePlate = (data) => Fetch.post("/Plate/ActivePlate", data);

Plate.ReStaking = (params) =>
  Fetch.post(
    `/plate/ReStaking?isAll=${params.isAll}&address=${params.address}`
  );

export default Plate;
