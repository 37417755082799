<template>
  <div class="wrapper sales-page">
    <!-- Header -->
    <Header />
    <!-- HeaderMenu -->
    <HeaderMenu />

    <div class="contents">
      <div class="layout">
        <div class="left">
          <div class="pc-only">
            <Sidebar />
          </div>
        </div>
        <div class="right">
          <h1>{{ $t("SALES MANAGEMENT") }}</h1>

          <div class="balance">
            <h2>{{ $t("THIS MONTH'S BINARY BONUS") }}</h2>
            <div class="total-amount">
              <span>{{ monthBonus }}</span> USDT
            </div>
            <ul class="list">
              <li>
                <h3>{{ $t("LEFT USER") }}</h3>
                <div class="amount">
                  <span>{{ leftUserCount }}</span>
                </div>
              </li>
              <li>
                <h3>{{ $t("RIGHT USER") }}</h3>
                <div class="amount">
                  <span>{{ rightUserCount }}</span>
                </div>
              </li>
            </ul>
          </div>

          <div class="form">
            <form>
              <!-- トータル売上（左） -->
              <div class="input-area">
                <label class="label" for="allamount-left">
                  <input
                    type="text"
                    name=""
                    id="allamount-left"
                    :value="leftPerformance"
                    readonly
                  />
                  <span class="group-label"
                    >{{ $t("Total sales") }}（{{ $t("Left") }}）</span
                  >
                </label>
                <span class="currency-txt">USDT</span>
              </div>
              <!-- トータル売上（右） -->
              <div class="input-area">
                <label class="label" for="allamount-right">
                  <input
                    type="text"
                    name=""
                    id="allamount-right"
                    :value="rightPerformance"
                    readonly
                  />
                  <span class="group-label"
                    >{{ $t("Total sales") }}（{{ $t("Right") }}）</span
                  >
                </label>
                <span class="currency-txt">USDT</span>
              </div>
              <!-- バイナリー売上（左） -->
              <div class="input-area">
                <label class="label" for="binary-left">
                  <input
                    type="text"
                    name=""
                    id="binary-left"
                    :value="leftNoBumpPerformance"
                    readonly
                  />
                  <span class="group-label"
                    >{{ $t("Binary sales") }}（{{ $t("Left") }}）</span
                  >
                </label>
                <span class="currency-txt">USDT</span>
              </div>
              <!-- バイナリー売上（右） -->
              <div class="input-area">
                <label class="label" for="binary-right">
                  <input
                    type="text"
                    name=""
                    id="binary-right"
                    :value="rightNoBumpPerformance"
                    readonly
                  />
                  <span class="group-label"
                    >{{ $t("Binary sales") }}（{{ $t("Right") }}）</span
                  >
                </label>
                <span class="currency-txt">USDT</span>
              </div>
            </form>
          </div>
          <div class="link">
            <router-link to="/organizationchart" class="link-over">{{
              $t("ORGANIZATION CHART")
            }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- /.contents -->
    <van-overlay :show="loading">
      <div class="loading-position" @click.stop>
        <van-loading type="spinner" color="#1989fa" />
      </div>
    </van-overlay>
    <Footer title="SALES MANAGEMENT" />
  </div>
</template>
<style scoped lang="less">
.wrapper.sales-page {
  background-color: #2a3440;
  background-image: url(../assets/img/bg-back1-img.png);
  background-position: 50% 180px;
  background-repeat: no-repeat;
  min-height: 100vh;
  @media screen and (min-width: 767px) {
    background: url(../assets/img/bg-back1-img.png), #2a3440;
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}
.loading-position {
  position: absolute;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
}
/* Sales-page
----------------------------------------- */
.sales-page {
  .right {
    padding: 80px 0 40px;
    @media screen and (min-width: 767px) {
      padding: 0 0 20px;
    }
  }
  h1 {
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 1px;
    margin: 20px 0 40px;
    padding: 6px 20px 6px 28px;
    position: relative;
    &::after {
      content: "";
      height: 100%;
      width: 5px;
      border-radius: 4px;
      position: absolute;
      left: 10px;
      top: 0;
      background-color: #fff;
    }
  }
  .balance {
    text-align: center;
    color: #fff;
    padding: 0 10px;
    overflow: hidden;
    h2 {
      font-size: 14px;
      letter-spacing: 1px;
      font-weight: 500;
      margin: 10px 0;
    }
    .total-amount {
      span {
        display: inline-block;
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 3px;
        margin-right: 20px;
      }
    }
    .list {
      margin: 40px 0;
      display: flex;
      justify-content: center;
      li {
        padding: 6px 10px;
        width: 30%;
        border-right: 1px solid #fff;
        border-left: 1px solid #fff;
        color: #fff;
        text-align: center;
        &:last-child {
          border-left: none;
        }
      }
      h3 {
        font-size: 12px;
        margin-bottom: 10px;
      }
      .amount {
        font-size: 12px;
        b {
          font-size: 18px;
        }
      }
      span {
        display: block;
        font-weight: 500;
        letter-spacing: 1px;
        font-size: 20px;
        margin-bottom: 2px;
      }
    }
  }
  .form {
    padding: 0 20px;
    .input-area {
      background-color: #fff;
      box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.2),
        0 2px 4px -1px rgba(0, 0, 0, 0.3);
      margin-bottom: 20px;
      padding: 10px 10px 10px 10px;
      height: 54px;
      position: relative;
      .label {
        position: relative;
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
      }
      .group-label {
        position: absolute;
        top: 44%;
        transform: translateY(-50%);
        transition: 0.2s;
        font-size: 14px;
        color: #2a3440;
      }
      .currency-txt {
        color: #2a3440;
        font-size: 12px;
        position: absolute;
        right: 10px;
        bottom: 16px;
      }
      input {
        font-size: 14px;
        width: 100%;
        height: 90%;
        border: 0;
        outline: 0;
        color: #2a3440;
        cursor: pointer;
        border-bottom: 2px solid #2a3440;
        letter-spacing: 1px;
        font-weight: 500;
        text-align: right;
        padding-right: 40px;
        &:focus ~ .group-label,
        &:not(:placeholder-shown) ~ .group-label {
          font-size: 10px;
          top: -6px;
          transform: translateY(0);
          color: #2a3440;
        }
        &::placeholder {
          color: #2a3440;
        }
      }
    }
  }
  .link {
    padding: 0 20px;
    text-align: center;
    margin-top: 40px;
    a {
      color: #fff;
      display: inline-block;
      border: 1px solid #fff;
      padding: 14px 60px;
      border-radius: 30px;
    }
  }
}
</style>
<script>
import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
import Account from "@/js/api/account";
export default {
  name: "Sales",
  components: {
    Header,
    HeaderMenu,
    Sidebar,
    Footer,
  },
  data: () => {
    return {
      title: "Sales",
      loading: false,
      monthBonus: "--", //当月奖金
      leftUserCount: "--", //左边用户数量
      rightUserCount: "--", //右边用户数量
      leftPerformance: "--", //左边业绩
      rightPerformance: "--", //右边业绩
      leftNoBumpPerformance: "--", //还没有对碰的业绩（左边）
      rightNoBumpPerformance: "--", //还没有对碰的业绩（右边）
    };
  },
  created() {
    this.loading = true;
    Account.getCommunityInfo()
      .then((res) => {
        this.monthBonus = res.data.monthBumpBonus;
        this.leftUserCount = res.data.leftUserCount;
        this.leftPerformance = res.data.leftPerformance;
        this.leftNoBumpPerformance = res.data.leftBumpPerformance;
        this.rightUserCount = res.data.rightUserCount;
        this.rightPerformance = res.data.rightPerformance;
        this.rightNoBumpPerformance = res.data.rightBumpPerformance;
      })
      .finally(() => (this.loading = false));
  },
  methods: {},
  computed: {},
  mounted: function () {},
};
</script>
